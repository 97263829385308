import React, { useState } from 'react';
import { X, Euro, Clock, Upload, FileText, Phone, Building2 } from 'lucide-react';
import { useClients } from '../../contexts/ClientsContext';
import { updateContract } from '../../lib/firebase/contracts';
import { sendEmail } from '../../lib/email/sendEmail';
import { emailTemplates } from '../../lib/email/templates';
import type { Contract } from '../../types';

interface EditContractModalProps {
  contract: Contract;
  isOpen: boolean;
  onClose: () => void;
}

export function EditContractModal({ contract, isOpen, onClose }: EditContractModalProps) {
  const { clients } = useClients();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [formData, setFormData] = useState({
    ...contract,
    documents: [] as File[]
  });

  const phonePrefixes = [
    { value: '+33', label: 'France (+33)' },
    { value: '+32', label: 'Belgique (+32)' },
    { value: '+41', label: 'Suisse (+41)' },
    { value: '+352', label: 'Luxembourg (+352)' },
    { value: '+44', label: 'Royaume-Uni (+44)' },
    { value: '+49', label: 'Allemagne (+49)' },
    { value: '+34', label: 'Espagne (+34)' },
    { value: '+39', label: 'Italie (+39)' }
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Mettre à jour le contrat avec le statut "pending_validation"
      await updateContract(contract.id, {
        ...formData,
        status: 'pending_validation',
        updatedAt: new Date().toISOString()
      });

      // Trouver le client pour envoyer la notification
      const client = clients.find(c => c.id === contract.clientId);
      if (client?.email) {
        await sendEmail({
          to: client.email,
          template: emailTemplates.contractUpdated({
            ...formData,
            id: contract.id,
            clientId: contract.clientId,
            status: 'pending_validation',
            createdAt: contract.createdAt,
            updatedAt: new Date().toISOString()
          }, client.name)
        });
      }

      onClose();
    } catch (err) {
      console.error('Error updating contract:', err);
      setError('Erreur lors de la modification du contrat');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'number' ? parseFloat(value) || 0 : value
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-900">Modifier le contrat</h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {error && (
            <div className="p-3 bg-red-50 border border-red-200 rounded-lg text-red-600 text-sm">
              {error}
            </div>
          )}

          <div className="p-4 bg-yellow-50 border border-yellow-200 rounded-lg">
            <p className="text-sm text-yellow-800">
              Les modifications apportées au contrat devront être validées par le client avant d'être appliquées.
            </p>
          </div>

          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Nom du contrat
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={formData.name}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
                Date de début (optionnel)
              </label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate || ''}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              />
            </div>
            <div>
              <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
                Date de fin (optionnel)
              </label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={formData.endDate || ''}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              />
            </div>
          </div>

          <div>
            <label className="flex items-center gap-2 mb-4">
              <input
                type="checkbox"
                name="isSubcontractor"
                checked={formData.isSubcontractor}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  isSubcontractor: e.target.checked
                }))}
                className="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
              />
              <span className="text-sm text-gray-600">Contrat sous-traitant</span>
            </label>

            <div className="grid grid-cols-3 gap-4">
              <div>
                <label htmlFor="basePrice" className="block text-sm font-medium text-gray-700 mb-1">
                  {formData.isSubcontractor ? 'Taux horaire (€)' : 'Prix de base (€)'}
                </label>
                <div className="relative">
                  <Euro className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                  <input
                    type="number"
                    id="basePrice"
                    name="basePrice"
                    min="0"
                    step="0.01"
                    required
                    value={formData.basePrice}
                    onChange={handleChange}
                    className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="baseHours" className="block text-sm font-medium text-gray-700 mb-1">
                  Heures incluses
                </label>
                <div className="relative">
                  <Clock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                  <input
                    type="number"
                    id="baseHours"
                    name="baseHours"
                    min="0"
                    step="0.5"
                    required
                    value={formData.baseHours}
                    onChange={handleChange}
                    className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="overtimeRate" className="block text-sm font-medium text-gray-700 mb-1">
                  Taux horaire sup. (€)
                </label>
                <div className="relative">
                  <Euro className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                  <input
                    type="number"
                    id="overtimeRate"
                    name="overtimeRate"
                    min="0"
                    step="0.01"
                    required
                    value={formData.overtimeRate}
                    onChange={handleChange}
                    className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
              </div>
            </div>
          </div>

          {formData.isSubcontractor && (
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="subcontractorRate" className="block text-sm font-medium text-gray-700 mb-1">
                  Taux sous-traitant (€/h)
                </label>
                <div className="relative">
                  <Euro className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                  <input
                    type="number"
                    id="subcontractorRate"
                    name="subcontractorRate"
                    min="0"
                    step="0.01"
                    required
                    value={formData.subcontractorRate || 0}
                    onChange={handleChange}
                    className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="subcontractorOvertimeRate" className="block text-sm font-medium text-gray-700 mb-1">
                  Taux sup. sous-traitant (€/h)
                </label>
                <div className="relative">
                  <Euro className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                  <input
                    type="number"
                    id="subcontractorOvertimeRate"
                    name="subcontractorOvertimeRate"
                    min="0"
                    step="0.01"
                    required
                    value={formData.subcontractorOvertimeRate || 0}
                    onChange={handleChange}
                    className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
              </div>
            </div>
          )}

          <div>
            <label htmlFor="supportPhone" className="block text-sm font-medium text-gray-700 mb-1">
              Téléphone support (optionnel)
            </label>
            <div className="grid grid-cols-3 gap-2">
              <select
                id="supportPhonePrefix"
                name="supportPhonePrefix"
                value={formData.supportPhonePrefix || '+33'}
                onChange={handleChange}
                className="w-full px-2 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              >
                {phonePrefixes.map(prefix => (
                  <option key={prefix.value} value={prefix.value}>
                    {prefix.label}
                  </option>
                ))}
              </select>
              <div className="col-span-2 relative">
                <Phone className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <input
                  type="tel"
                  id="supportPhone"
                  name="supportPhone"
                  value={formData.supportPhone || ''}
                  onChange={handleChange}
                  placeholder="1 23 45 67 89"
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                />
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Documents
            </label>
            <div className="border-2 border-dashed border-gray-300 rounded-lg p-6">
              <input
                type="file"
                multiple
                onChange={(e) => {
                  if (e.target.files) {
                    setFormData(prev => ({
                      ...prev,
                      documents: Array.from(e.target.files || [])
                    }));
                  }
                }}
                className="hidden"
                id="documents"
              />
              <label
                htmlFor="documents"
                className="flex flex-col items-center justify-center cursor-pointer"
              >
                <Upload className="w-8 h-8 text-gray-400 mb-2" />
                <span className="text-sm text-gray-600">
                  Cliquez pour ajouter des documents
                </span>
              </label>
            </div>
          </div>

          <div>
            <label htmlFor="terms" className="block text-sm font-medium text-gray-700 mb-1">
              Commentaires
            </label>
            <textarea
              id="terms"
              name="terms"
              value={formData.terms || ''}
              onChange={handleChange}
              rows={4}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            />
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 disabled:opacity-50"
            >
              {loading ? 'Modification...' : 'Enregistrer les modifications'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
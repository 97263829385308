import { collection, addDoc, getDocs, query, orderBy, updateDoc, doc, where, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import type { Site } from '../../types';

const COLLECTION = 'sites';

export async function getSites(clientId?: string) {
  try {
    if (!clientId) {
      throw new Error('Client ID is required');
    }

    const q = query(
      collection(db, COLLECTION),
      where('clientId', '==', clientId)
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Site[];
  } catch (error) {
    console.error('Error fetching sites:', error);
    throw error;
  }
}

export async function createSite(siteData: Omit<Site, 'id' | 'createdAt' | 'updatedAt'>) {
  try {
    if (!siteData.clientId) {
      throw new Error('Client ID is required');
    }

    const site = {
      ...siteData,
      createdAt: Timestamp.now().toDate().toISOString(),
      updatedAt: Timestamp.now().toDate().toISOString()
    };

    const docRef = await addDoc(collection(db, COLLECTION), site);
    return docRef.id;
  } catch (error) {
    console.error('Error creating site:', error);
    throw error;
  }
}

export async function updateSite(id: string, data: Partial<Site>) {
  try {
    const docRef = doc(db, COLLECTION, id);
    await updateDoc(docRef, {
      ...data,
      updatedAt: Timestamp.now().toDate().toISOString()
    });
  } catch (error) {
    console.error('Error updating site:', error);
    throw error;
  }
}

export async function getClientSites(clientId: string): Promise<Site[]> {
  return getSites(clientId);
}
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';

export async function uploadFile(file: File, path: string): Promise<string> {
  const storageRef = ref(storage, path);
  const snapshot = await uploadBytes(storageRef, file);
  return getDownloadURL(snapshot.ref);
}

export async function uploadFiles(files: File[], basePath: string): Promise<string[]> {
  const uploadPromises = files.map(file => {
    const path = `${basePath}/${Date.now()}-${file.name}`;
    return uploadFile(file, path);
  });
  return Promise.all(uploadPromises);
}

export async function uploadInterventionFiles(
  interventionId: string,
  photos: File[],
  attachments: File[]
): Promise<{ photoUrls: string[], attachmentUrls: string[] }> {
  const photoUrls = await uploadFiles(photos, `interventions/${interventionId}/photos`);
  const attachmentUrls = await uploadFiles(attachments, `interventions/${interventionId}/attachments`);
  return { photoUrls, attachmentUrls };
}
import { collection, addDoc, getDocs, query, where, updateDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import type { MonthlyInvoice } from '../../types';

const COLLECTION = 'monthly_invoices';

export async function getMonthlyInvoices(month: number, year: number) {
  try {
    const q = query(
      collection(db, COLLECTION),
      where('month', '==', month),
      where('year', '==', year)
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as MonthlyInvoice[];
  } catch (error) {
    console.error('Error fetching monthly invoices:', error);
    throw error;
  }
}

export async function createMonthlyInvoice(invoice: Omit<MonthlyInvoice, 'id'>) {
  try {
    const docRef = await addDoc(collection(db, COLLECTION), {
      ...invoice,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    });
    return docRef.id;
  } catch (error) {
    console.error('Error creating monthly invoice:', error);
    throw error;
  }
}

export async function updateMonthlyInvoice(id: string, data: Partial<MonthlyInvoice>) {
  try {
    const docRef = doc(db, COLLECTION, id);
    await updateDoc(docRef, {
      ...data,
      updatedAt: Timestamp.now()
    });
  } catch (error) {
    console.error('Error updating monthly invoice:', error);
    throw error;
  }
}
import React, { useState, useEffect } from 'react';
import { X, Calendar, Clock, MapPin, Euro, Building2, FileText, Package, User, AlertCircle } from 'lucide-react';
import { useInterventions } from '../../contexts/InterventionsContext';
import { useClients } from '../../contexts/ClientsContext';
import { useTechnicians } from '../../contexts/TechniciansContext';
import { AddressInput } from './AddressInput';
import { getNextTicketNumber } from '../../lib/firebase/ticketNumbers';
import { getClientContracts } from '../../lib/firebase/contracts';
import { getClientSites } from '../../lib/firebase/sites';
import type { Client, ValidatedAddress, Contract, Site } from '../../types';

interface CreateInterventionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function CreateInterventionModal({ isOpen, onClose }: CreateInterventionModalProps) {
  const { createNewIntervention } = useInterventions();
  const { clients } = useClients();
  const { technicians } = useTechnicians();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [validatedAddress, setValidatedAddress] = useState<ValidatedAddress | null>(null);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [sites, setSites] = useState<Site[]>([]);
  const [loadingClientData, setLoadingClientData] = useState(false);

  const [formData, setFormData] = useState({
    ticketNumber: '',
    clientTicketNumber: '',
    supportNumber: '',
    title: '',
    clientId: '',
    siteId: '',
    contractId: '',
    location: '',
    date: '',
    time: '',
    estimatedDuration: '',
    description: '',
    isTimeStrict: false,
    price: 0,
    expenses: 0,
    subcontractorPrice: 0,
    subcontractorCost: 0,
    mainTechnicianId: '',
    secondaryTechnicianIds: [] as string[],
    trackingNumbers: [] as string[]
  });

  useEffect(() => {
    const loadTicketNumber = async () => {
      try {
        const number = await getNextTicketNumber();
        setFormData(prev => ({ ...prev, ticketNumber: number }));
      } catch (err) {
        console.error('Error loading next ticket number:', err);
        setError('Erreur lors de la génération du numéro de ticket');
      }
    };

    if (isOpen) {
      loadTicketNumber();
    }
  }, [isOpen]);

  useEffect(() => {
    const loadClientData = async () => {
      if (!selectedClient?.id) {
        setContracts([]);
        setSites([]);
        return;
      }

      setLoadingClientData(true);
      try {
        const [contractsData, sitesData] = await Promise.all([
          getClientContracts(selectedClient.id),
          getClientSites(selectedClient.id)
        ]);
        setContracts(contractsData);
        setSites(sitesData);
      } catch (err) {
        console.error('Error loading client data:', err);
        setError('Erreur lors du chargement des données client');
      } finally {
        setLoadingClientData(false);
      }
    };

    loadClientData();
  }, [selectedClient]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.clientId || !selectedClient || !validatedAddress) {
      setError('Veuillez remplir tous les champs requis et valider l\'adresse');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await createNewIntervention({
        ...formData,
        client: selectedClient.name,
        location: validatedAddress.formattedAddress,
        coordinates: {
          lat: validatedAddress.lat,
          lng: validatedAddress.lng
        }
      });
      
      onClose();
    } catch (err) {
      setError('Erreur lors de la création de l\'intervention');
      console.error('Error creating intervention:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    if (name === 'clientId') {
      const client = clients.find(c => c.id === value);
      setSelectedClient(client || null);
      if (client) {
        setFormData(prev => ({
          ...prev,
          clientId: value,
          location: client.address
        }));
      }
    } else if (name === 'siteId') {
      const site = sites.find(s => s.id === value);
      if (site) {
        setFormData(prev => ({
          ...prev,
          siteId: value,
          location: site.address
        }));
      }
    } else if (name === 'contractId') {
      const contract = contracts.find(c => c.id === value);
      if (contract) {
        setFormData(prev => ({
          ...prev,
          contractId: value,
          price: contract.basePrice,
          subcontractorPrice: contract.subcontractorRate || 0,
          supportNumber: contract.supportPhone ? `${contract.supportPhonePrefix} ${contract.supportPhone}` : ''
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : 
                type === 'number' ? parseFloat(value) || 0 : value
      }));
    }
  };

  const addTrackingNumber = () => {
    const number = prompt('Entrez le numéro de tracking:');
    if (number?.trim()) {
      setFormData(prev => ({
        ...prev,
        trackingNumbers: [...prev.trackingNumbers, number.trim()]
      }));
    }
  };

  const removeTrackingNumber = (index: number) => {
    setFormData(prev => ({
      ...prev,
      trackingNumbers: prev.trackingNumbers.filter((_, i) => i !== index)
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-900">Nouvelle intervention</h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {error && (
            <div className="p-3 bg-red-50 border border-red-200 rounded-lg flex items-center gap-2 text-red-600">
              <AlertCircle className="w-5 h-5 flex-shrink-0" />
              <p className="text-sm">{error}</p>
            </div>
          )}

          <div>
            <label htmlFor="ticketNumber" className="block text-sm font-medium text-gray-700 mb-1">
              N° ticket
            </label>
            <input
              type="text"
              id="ticketNumber"
              name="ticketNumber"
              value={formData.ticketNumber}
              disabled
              className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-50 text-gray-500"
            />
          </div>

          <div>
            <label htmlFor="clientId" className="block text-sm font-medium text-gray-700 mb-1">
              Client
            </label>
            <div className="relative">
              <Building2 className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
              <select
                id="clientId"
                name="clientId"
                required
                value={formData.clientId}
                onChange={handleChange}
                className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Sélectionner un client</option>
                {clients.map(client => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="clientTicketNumber" className="block text-sm font-medium text-gray-700 mb-1">
                N° ticket client
              </label>
              <input
                type="text"
                id="clientTicketNumber"
                name="clientTicketNumber"
                value={formData.clientTicketNumber}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label htmlFor="supportNumber" className="block text-sm font-medium text-gray-700 mb-1">
                N° support
              </label>
              <input
                type="text"
                id="supportNumber"
                name="supportNumber"
                value={formData.supportNumber}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="siteId" className="block text-sm font-medium text-gray-700 mb-1">
                Site
              </label>
              <select
                id="siteId"
                name="siteId"
                value={formData.siteId}
                onChange={handleChange}
                disabled={loadingClientData}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50 disabled:text-gray-500"
              >
                <option value="">Sélectionner un site</option>
                {sites.map(site => (
                  <option key={site.id} value={site.id}>
                    {site.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="contractId" className="block text-sm font-medium text-gray-700 mb-1">
                Contrat
              </label>
              <select
                id="contractId"
                name="contractId"
                value={formData.contractId}
                onChange={handleChange}
                disabled={loadingClientData}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50 disabled:text-gray-500"
              >
                <option value="">Sélectionner un contrat</option>
                {contracts.map(contract => (
                  <option key={contract.id} value={contract.id}>
                    {contract.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
              Titre
            </label>
            <div className="relative">
              <FileText className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
              <input
                type="text"
                id="title"
                name="title"
                required
                value={formData.title}
                onChange={handleChange}
                className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Ex: Installation serveur"
              />
            </div>
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              required
              value={formData.description}
              onChange={handleChange}
              rows={4}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Description détaillée de l'intervention..."
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
                Date
              </label>
              <div className="relative">
                <Calendar className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <input
                  type="date"
                  id="date"
                  name="date"
                  required
                  value={formData.date}
                  onChange={handleChange}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div>
              <label htmlFor="time" className="block text-sm font-medium text-gray-700 mb-1">
                Heure
              </label>
              <div className="relative">
                <Clock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <input
                  type="time"
                  id="time"
                  name="time"
                  required
                  value={formData.time}
                  onChange={handleChange}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="estimatedDuration" className="block text-sm font-medium text-gray-700 mb-1">
              Durée estimée (heures)
            </label>
            <div className="relative">
              <Clock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
              <input
                type="number"
                id="estimatedDuration"
                name="estimatedDuration"
                min="0.5"
                step="0.5"
                required
                value={formData.estimatedDuration}
                onChange={handleChange}
                className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="2.5"
              />
            </div>
          </div>

          <div>
            <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
              Lieu d'intervention
            </label>
            <AddressInput
              value={formData.location}
              onChange={(address, validated) => {
                setFormData(prev => ({ ...prev, location: address }));
                if (validated) {
                  setValidatedAddress(validated);
                }
              }}
              required
              error={error}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="mainTechnicianId" className="block text-sm font-medium text-gray-700 mb-1">
                Technicien principal
              </label>
              <div className="relative">
                <User className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <select
                  id="mainTechnicianId"
                  name="mainTechnicianId"
                  value={formData.mainTechnicianId}
                  onChange={handleChange}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Sélectionner un technicien</option>
                  {technicians.map(tech => (
                    <option key={tech.id} value={tech.id}>
                      {tech.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div>
              <label htmlFor="secondaryTechnicianIds" className="block text-sm font-medium text-gray-700 mb-1">
                Techniciens secondaires
              </label>
              <select
                id="secondaryTechnicianIds"
                name="secondaryTechnicianIds"
                multiple
                value={formData.secondaryTechnicianIds}
                onChange={(e) => {
                  const options = Array.from(e.target.selectedOptions, option => option.value);
                  setFormData(prev => ({
                    ...prev,
                    secondaryTechnicianIds: options
                  }));
                }}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                {technicians
                  .filter(tech => tech.id !== formData.mainTechnicianId)
                  .map(tech => (
                    <option key={tech.id} value={tech.id}>
                      {tech.name}
                    </option>
                  ))}
              </select>
              <p className="mt-1 text-sm text-gray-500">
                Maintenez Ctrl pour sélectionner plusieurs techniciens
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="price" className="block text-sm font-medium text-gray-700 mb-1">
                Prix de vente (€)
              </label>
              <div className="relative">
                <Euro className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <input
                  type="number"
                  id="price"
                  name="price"
                  min="0"
                  step="0.01"
                  required
                  value={formData.price}
                  onChange={handleChange}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div>
              <label htmlFor="expenses" className="block text-sm font-medium text-gray-700 mb-1">
                Frais (€)
              </label>
              <div className="relative">
                <Euro className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <input
                  type="number"
                  id="expenses"
                  name="expenses"
                  min="0"
                  step="0.01"
                  value={formData.expenses}
                  onChange={handleChange}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="subcontractorPrice" className="block text-sm font-medium text-gray-700 mb-1">
                Taux horaire sous-traitant (€/h)
              </label>
              <div className="relative">
                <Euro className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <input
                  type="number"
                  id="subcontractorPrice"
                  name="subcontractorPrice"
                  min="0"
                  step="0.01"
                  value={formData.subcontractorPrice}
                  onChange={handleChange}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <p className="mt-1 text-xs text-gray-500">
                Taux horaire de base du sous-traitant
              </p>
            </div>

            <div>
              <label htmlFor="subcontractorCost" className="block text-sm font-medium text-gray-700 mb-1">
                Frais sous-traitant (€)
              </label>
              <div className="relative">
                <Euro className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <input
                  type="number"
                  id="subcontractorCost"
                  name="subcontractorCost"
                  min="0"
                  step="0.01"
                  value={formData.subcontractorCost}
                  onChange={handleChange}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <p className="mt-1 text-xs text-gray-500">
                Frais additionnels pour le sous-traitant
              </p>
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Numéros de tracking
              </label>
              <button
                type="button"
                onClick={addTrackingNumber}
                className="text-sm text-blue-600 hover:text-blue-700 flex items-center gap-1"
              >
                <Package className="w-4 h-4" />
                Ajouter un numéro
              </button>
            </div>
            {formData.trackingNumbers.length > 0 && (
              <div className="space-y-2">
                {formData.trackingNumbers.map((number, index) => (
                  <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded-lg">
                    <div className="flex items-center gap-2">
                      <Package className="w-4 h-4 text-gray-400" />
                      <span className="text-sm text-gray-600">{number}</span>
                    </div>
                    <button
                      type="button"
                      onClick={() => removeTrackingNumber(index)}
                      className="text-sm text-red-600 hover:text-red-700"
                    >
                      Supprimer
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                name="isTimeStrict"
                checked={formData.isTimeStrict}
                onChange={handleChange}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="text-sm text-gray-600">Horaire à respecter impérativement</span>
            </label>
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
            >
              {loading ? (
                <>
                  <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  Création...
                </>
              ) : (
                'Créer l\'intervention'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
import { collection, addDoc, getDocs, query, orderBy, updateDoc, doc, deleteDoc, where, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { uploadInterventionFiles } from './storage';
import type { Intervention, CompletionDetails } from '../../types';

const COLLECTION = 'interventions';

export async function getInterventions(status?: 'pending' | 'in_progress' | 'completed' | 'cancelled') {
  try {
    let q = query(collection(db, COLLECTION), orderBy('date', 'desc'));
    
    if (status) {
      q = query(q, where('status', '==', status));
    }
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Intervention[];
  } catch (error) {
    console.error('Error fetching interventions:', error);
    throw error;
  }
}

export async function createIntervention(intervention: Omit<Intervention, 'id'>) {
  try {
    const docRef = await addDoc(collection(db, COLLECTION), {
      ...intervention,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    });
    return docRef.id;
  } catch (error) {
    console.error('Error creating intervention:', error);
    throw error;
  }
}

export async function updateIntervention(id: string, data: Partial<Intervention>) {
  try {
    const docRef = doc(db, COLLECTION, id);
    await updateDoc(docRef, {
      ...data,
      updatedAt: Timestamp.now()
    });
  } catch (error) {
    console.error('Error updating intervention:', error);
    throw error;
  }
}

export async function deleteIntervention(id: string) {
  try {
    await deleteDoc(doc(db, COLLECTION, id));
  } catch (error) {
    console.error('Error deleting intervention:', error);
    throw error;
  }
}

export async function closeIntervention(
  id: string,
  completionDetails: CompletionDetails
): Promise<void> {
  try {
    // Upload des fichiers
    const { photoUrls, attachmentUrls } = await uploadInterventionFiles(
      id,
      completionDetails.photos,
      completionDetails.attachments
    );

    // Préparation des données de mise à jour
    const updateData = {
      status: 'completed',
      completionDetails: {
        ...completionDetails,
        photos: photoUrls,
        attachments: attachmentUrls,
        completedAt: Timestamp.now()
      },
      updatedAt: Timestamp.now()
    };

    // Mise à jour de l'intervention
    const docRef = doc(db, COLLECTION, id);
    await updateDoc(docRef, updateData);
  } catch (error) {
    console.error('Error closing intervention:', error);
    throw error;
  }
}
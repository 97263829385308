import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { InterventionsProvider } from './contexts/InterventionsContext';
import { TechniciansProvider } from './contexts/TechniciansContext';
import { EquipmentProvider } from './contexts/EquipmentContext';
import { ClientsProvider } from './contexts/ClientsContext';
import { ContractsProvider } from './contexts/ContractsContext';
import { AccountingProvider } from './contexts/AccountingContext';
import { AuthenticatedApp } from './components/AuthenticatedApp';
import { AuthPage } from './pages/AuthPage';
import { ErrorBoundary } from './components/ErrorBoundary';
import { useAuth } from './contexts/AuthContext';

function AppContent() {
  const { user } = useAuth();
  return user ? (
    <InterventionsProvider>
      <TechniciansProvider>
        <EquipmentProvider>
          <ClientsProvider>
            <ContractsProvider>
              <AccountingProvider>
                <AuthenticatedApp />
              </AccountingProvider>
            </ContractsProvider>
          </ClientsProvider>
        </EquipmentProvider>
      </TechniciansProvider>
    </InterventionsProvider>
  ) : (
    <AuthPage />
  );
}

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
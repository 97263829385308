import React, { createContext, useContext, useState, useEffect } from 'react';
import { getContracts, createContract, updateContract } from '../lib/firebase/contracts';
import type { Contract } from '../types';

interface ContractsContextType {
  contracts: Contract[];
  loading: boolean;
  error: string | null;
  createNewContract: (contract: Omit<Contract, 'id' | 'createdAt' | 'updatedAt'>) => Promise<string>;
  updateContractData: (id: string, data: Partial<Contract>) => Promise<void>;
  refreshContracts: () => Promise<void>;
}

const ContractsContext = createContext<ContractsContextType | null>(null);

export function ContractsProvider({ children }: { children: React.ReactNode }) {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchContracts = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getContracts();
      setContracts(data);
    } catch (err) {
      console.error('Error fetching contracts:', err);
      setError('Erreur lors du chargement des contrats');
    } finally {
      setLoading(false);
    }
  };

  // Rafraîchir les données toutes les 30 secondes
  useEffect(() => {
    fetchContracts();

    const interval = setInterval(() => {
      fetchContracts();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const createNewContract = async (contractData: Omit<Contract, 'id' | 'createdAt' | 'updatedAt'>) => {
    const id = await createContract(contractData);
    await fetchContracts();
    return id;
  };

  const updateContractData = async (id: string, data: Partial<Contract>) => {
    await updateContract(id, data);
    await fetchContracts();
  };

  const value = {
    contracts,
    loading,
    error,
    createNewContract,
    updateContractData,
    refreshContracts: fetchContracts
  };

  return (
    <ContractsContext.Provider value={value}>
      {children}
    </ContractsContext.Provider>
  );
}

export function useContracts() {
  const context = useContext(ContractsContext);
  if (!context) {
    throw new Error('useContracts must be used within a ContractsProvider');
  }
  return context;
}
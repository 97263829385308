import React from 'react';
import { CompanyProfile } from '../components/settings/CompanyProfile';
import { PageHeader } from '../components/layout/PageHeader';
import { Building2 } from 'lucide-react';

export function Company() {
  return (
    <div className="space-y-6">
      <PageHeader
        title="Informations entreprise"
        subtitle="Gérez les informations de votre entreprise"
        icon={Building2}
        gradient="blue"
      />

      <CompanyProfile />
    </div>
  );
}
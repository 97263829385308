import React from 'react';
import { X, AlertCircle, Clock, MapPin, Building2 } from 'lucide-react';
import type { Intervention } from '../../types';

interface OverdueInterventionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  interventions: Intervention[];
  onSelectIntervention: (intervention: Intervention) => void;
}

export function OverdueInterventionsModal({ 
  isOpen, 
  onClose, 
  interventions,
  onSelectIntervention 
}: OverdueInterventionsModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <AlertCircle className="w-6 h-6 text-red-600" />
              <h2 className="text-xl font-semibold text-gray-900">
                Interventions non clôturées ({interventions.length})
              </h2>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="p-6">
          <div className="space-y-4">
            {interventions.map((intervention) => (
              <div
                key={intervention.id}
                onClick={() => {
                  onSelectIntervention(intervention);
                  onClose();
                }}
                className="bg-gray-50 p-4 rounded-xl hover:bg-gray-100 transition-colors cursor-pointer"
              >
                <div className="space-y-3">
                  <div>
                    <p className="text-sm text-gray-500">#{intervention.ticketNumber}</p>
                    <h3 className="font-medium text-gray-900">{intervention.title}</h3>
                  </div>
                  <div className="space-y-2">
                    <div className="flex items-center gap-2 text-sm text-gray-600">
                      <Building2 className="w-4 h-4" />
                      {intervention.client}
                    </div>
                    <div className="flex items-center gap-2 text-sm text-gray-600">
                      <MapPin className="w-4 h-4" />
                      {intervention.location}
                    </div>
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-2 text-sm text-gray-600">
                        <Clock className="w-4 h-4" />
                        {intervention.time}
                      </div>
                      {intervention.isTimeStrict && (
                        <span className="text-xs bg-red-100 text-red-600 px-2 py-1 rounded-full">
                          Horaire impératif
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
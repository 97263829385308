import React, { useState } from 'react';
import { LogIn, UserPlus } from 'lucide-react';
import { LoginForm } from '../components/auth/LoginForm';
import { SignupForm } from '../components/auth/SignupForm';

export function AuthPage() {
  const [isSignup, setIsSignup] = useState(false);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 flex items-center justify-center p-4">
      <div className="w-full max-w-[1000px] grid md:grid-cols-2 gap-8 items-center">
        {/* Welcome Section */}
        <div className="bg-white/80 backdrop-blur-sm p-12 rounded-2xl shadow-xl relative overflow-hidden order-2 md:order-1">
          <div className="relative z-10">
            <div className="w-12 h-12 bg-blue-600 rounded-xl flex items-center justify-center mb-8">
              <LogIn className="w-6 h-6 text-white" />
            </div>
            
            <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
              {isSignup ? 'Bienvenue sur DeltAPP !' : 'Ravi de vous revoir !'}
            </h2>
            
            <p className="text-gray-600 mb-8 text-lg leading-relaxed">
              {isSignup 
                ? 'Créez votre compte pour commencer à gérer vos interventions efficacement et optimiser la productivité de votre équipe.'
                : 'Connectez-vous pour accéder à votre tableau de bord et gérer vos interventions en toute simplicité.'}
            </p>

            <button
              onClick={() => setIsSignup(!isSignup)}
              className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-xl font-medium hover:from-blue-700 hover:to-indigo-700 transition-all shadow-lg hover:shadow-xl"
            >
              {isSignup ? (
                <>
                  <LogIn className="w-5 h-5" />
                  Se connecter
                </>
              ) : (
                <>
                  <UserPlus className="w-5 h-5" />
                  Créer un compte
                </>
              )}
            </button>
          </div>

          {/* Decorative Elements */}
          <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-blue-600/10 to-indigo-600/10 rounded-full -mr-32 -mt-32 blur-3xl" />
          <div className="absolute bottom-0 left-0 w-64 h-64 bg-gradient-to-tr from-purple-600/10 to-pink-600/10 rounded-full -ml-32 -mb-32 blur-3xl" />
        </div>

        {/* Login/Signup Form */}
        <div className="bg-white p-12 rounded-2xl shadow-xl order-1 md:order-2">
          <div className="mb-8">
            <h1 className="text-2xl font-bold text-gray-900 mb-2">
              {isSignup ? 'Créer un compte' : 'Se connecter'}
            </h1>
            <p className="text-gray-600">
              {isSignup 
                ? 'Remplissez le formulaire ci-dessous pour créer votre compte'
                : 'Entrez vos identifiants pour accéder à votre compte'}
            </p>
          </div>
          
          {isSignup ? (
            <SignupForm onCancel={() => setIsSignup(false)} />
          ) : (
            <LoginForm />
          )}
        </div>
      </div>
    </div>
  );
}
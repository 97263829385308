import React, { createContext, useContext, useState, useEffect } from 'react';
import { User } from 'firebase/auth';
import { auth, db } from '../lib/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { signIn, signOut, getCurrentUserProfile } from '../lib/firebase/auth';
import type { UserRole } from '../types';

interface AuthContextType {
  user: User | null;
  userProfile: { 
    id: string;
    name: string;
    email: string;
    role: UserRole;
    company?: string | null;
    [key: string]: any;
  } | null;
  loading: boolean;
  error: string | null;
  signIn: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  refreshUser: () => Promise<void>;
  hasRole: (roles: UserRole | UserRole[]) => boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<AuthContextType['userProfile']>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      try {
        setLoading(true);
        setUser(user);
        
        if (user) {
          // Récupérer le profil utilisateur depuis Firestore
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserProfile({
              id: user.uid,
              name: userData.name || user.displayName || '',
              email: user.email || '',
              role: userData.role,
              company: userData.company || null,
              ...userData
            });
          } else {
            setError('Profil utilisateur non trouvé');
          }
        } else {
          setUserProfile(null);
        }
      } catch (err) {
        console.error('Auth state change error:', err);
        setError('Error loading user profile');
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = async (email: string, password: string) => {
    try {
      setError(null);
      setLoading(true);
      const { user, profile } = await signIn(email, password);
      setUser(user);
      setUserProfile(profile);
    } catch (err: any) {
      console.error('Login error:', err);
      setError(err.message || 'Authentication failed');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      setLoading(true);
      await signOut();
      setUser(null);
      setUserProfile(null);
      setError(null);
    } catch (err) {
      console.error('Logout error:', err);
      setError('Error during logout');
    } finally {
      setLoading(false);
    }
  };

  const refreshUser = async () => {
    try {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserProfile({
            id: user.uid,
            name: userData.name || user.displayName || '',
            email: user.email || '',
            role: userData.role,
            company: userData.company || null,
            ...userData
          });
        }
      }
    } catch (err) {
      console.error('Error refreshing user:', err);
      setError('Error refreshing user profile');
    }
  };

  const hasRole = (roles: UserRole | UserRole[]) => {
    if (!userProfile) return false;
    if (Array.isArray(roles)) {
      return roles.includes(userProfile.role);
    }
    return userProfile.role === roles;
  };

  const value = {
    user,
    userProfile,
    loading,
    error,
    signIn: handleSignIn,
    logout: handleLogout,
    refreshUser,
    hasRole
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
import React, { useState, useEffect } from 'react';
import { Filter, Search, Plus, Users } from 'lucide-react';
import { TechnicianStatus } from '../types';
import { useTechnicians } from '../contexts/TechniciansContext';
import { TechniciansList } from '../components/technicians/TechniciansList';
import { CreateTechnicianModal } from '../components/technicians/CreateTechnicianModal';
import { EditTechnicianModal } from '../components/technicians/EditTechnicianModal';
import { TechniciansMap } from '../components/technicians/TechniciansMap';
import { PageHeader } from '../components/layout/PageHeader';
import type { Technician } from '../types';

export function Technicians() {
  const { technicians, loading, error, filterStatus, setFilterStatus, refreshTechnicians } = useTechnicians();
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedTechnician, setSelectedTechnician] = useState<Technician | null>(null);

  // Rafraîchir les données toutes les 30 secondes
  useEffect(() => {
    const interval = setInterval(() => {
      refreshTechnicians();
    }, 30000);

    return () => clearInterval(interval);
  }, [refreshTechnicians]);

  // Filtrer les techniciens
  const filteredTechnicians = technicians.filter(tech => {
    const matchesSearch = 
      tech.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tech.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tech.phone.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = filterStatus === 'all' || tech.status === filterStatus;
    return matchesSearch && matchesStatus;
  });

  return (
    <div className="space-y-6">
      <PageHeader
        title="Techniciens"
        subtitle="Gérez votre équipe technique"
        icon={Users}
        gradient="green"
        actions={
          <button
            onClick={() => setIsCreateModalOpen(true)}
            className="bg-white/90 backdrop-blur-sm text-green-600 px-4 py-2 rounded-lg hover:bg-white transition-colors flex items-center gap-2 shadow-sm"
          >
            <Plus className="w-4 h-4" />
            Nouveau technicien
          </button>
        }
      />

      {/* Carte des techniciens */}
      <TechniciansMap technicians={technicians} />

      {/* Filtres */}
      <div className="bg-white p-4 rounded-xl shadow-sm space-y-4">
        <div className="flex items-center gap-4">
          <div className="flex-1 relative">
            <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
            <input
              type="search"
              placeholder="Rechercher un technicien..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div className="flex items-center gap-2">
            <Filter className="w-5 h-5 text-gray-500" />
            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value as TechnicianStatus | 'all')}
              className="border border-gray-300 rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="all">Tous les statuts</option>
              <option value="available">Disponible</option>
              <option value="busy">Occupé</option>
              <option value="off">Indisponible</option>
              <option value="vacation">En congé</option>
            </select>
          </div>
        </div>
      </div>

      {/* Liste des techniciens */}
      <TechniciansList
        technicians={filteredTechnicians}
        onEdit={setSelectedTechnician}
      />

      {/* Modals */}
      <CreateTechnicianModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />

      {selectedTechnician && (
        <EditTechnicianModal
          technician={selectedTechnician}
          isOpen={!!selectedTechnician}
          onClose={() => setSelectedTechnician(null)}
        />
      )}
    </div>
  );
}
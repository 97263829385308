import { collection, addDoc, getDocs, query, orderBy, updateDoc, doc, deleteDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
import { Equipment, EquipmentStatus } from '../../types';

const COLLECTION = 'equipment';

export async function getEquipment(status?: EquipmentStatus) {
  try {
    let q = query(collection(db, COLLECTION), orderBy('name'));
    
    if (status) {
      q = query(q, where('status', '==', status));
    }
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Equipment[];
  } catch (error) {
    console.error('Error fetching equipment:', error);
    throw error;
  }
}

export async function createEquipment(equipment: Omit<Equipment, 'id'>) {
  try {
    const docRef = await addDoc(collection(db, COLLECTION), equipment);
    return docRef.id;
  } catch (error) {
    console.error('Error creating equipment:', error);
    throw error;
  }
}

export async function updateEquipment(id: string, data: Partial<Equipment>) {
  try {
    const docRef = doc(db, COLLECTION, id);
    await updateDoc(docRef, data);
  } catch (error) {
    console.error('Error updating equipment:', error);
    throw error;
  }
}

export async function deleteEquipment(id: string) {
  try {
    await deleteDoc(doc(db, COLLECTION, id));
  } catch (error) {
    console.error('Error deleting equipment:', error);
    throw error;
  }
}
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebase';

export async function getNextTicketNumber(): Promise<string> {
  try {
    // Get the last intervention to get the last ticket number
    const q = query(
      collection(db, 'interventions'),
      orderBy('ticketNumber', 'desc'),
      limit(1)
    );
    
    const snapshot = await getDocs(q);
    
    if (snapshot.empty) {
      // If no interventions exist, start with T00001
      return 'T00001';
    }

    const lastTicket = snapshot.docs[0].data().ticketNumber;
    
    // Extract the number part and increment it
    const numberPart = parseInt(lastTicket.substring(1), 10);
    if (isNaN(numberPart)) {
      // If parsing fails, start with T00001
      return 'T00001';
    }
    
    const nextNumber = numberPart + 1;
    // Format with leading zeros (5 digits)
    return `T${nextNumber.toString().padStart(5, '0')}`;
  } catch (error) {
    console.error('Error getting next ticket number:', error);
    // In case of error, generate a timestamp-based number as fallback
    const timestamp = Date.now();
    return `T${timestamp.toString().slice(-5).padStart(5, '0')}`;
  }
}
import React, { useState } from 'react';
import { X, Building2, MapPin, Clock, FileText, Euro, Package, Navigation, Play, CheckCircle, UserPlus, Edit, AlertCircle } from 'lucide-react';
import { useInterventions } from '../../contexts/InterventionsContext';
import { useAuth } from '../../contexts/AuthContext';
import { EditInterventionModal } from './EditInterventionModal';
import { CloseInterventionModal } from './CloseInterventionModal';
import type { Intervention } from '../../types';

interface InterventionDetailsModalProps {
  intervention: Intervention | null;
  isOpen: boolean;
  onClose: () => void;
}

export function InterventionDetailsModal({ intervention, isOpen, onClose }: InterventionDetailsModalProps) {
  const { updateInterventionStatus } = useInterventions();
  const { hasRole, userProfile } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);

  if (!isOpen || !intervention) return null;

  const isAssignedTechnician = userProfile?.technicianId === intervention.mainTechnicianId;
  const canEditIntervention = hasRole(['admin', 'dispatcher']) || isAssignedTechnician;

  const startIntervention = async () => {
    setLoading(true);
    setError(null);
    try {
      await updateInterventionStatus(intervention.id, 'in_progress');
      // Sauvegarder l'heure de début dans le localStorage
      localStorage.setItem(`intervention-${intervention.id}-start`, new Date().toISOString());
    } catch (err) {
      console.error('Error starting intervention:', err);
      setError('Erreur lors du démarrage de l\'intervention');
    } finally {
      setLoading(false);
    }
  };

  const openInMaps = (address: string) => {
    const encodedAddress = encodeURIComponent(address);
    window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`, '_blank');
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'in_progress':
        return 'bg-blue-100 text-blue-800';
      case 'completed':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'pending':
        return 'En attente';
      case 'in_progress':
        return 'En cours';
      case 'completed':
        return 'Terminée';
      default:
        return 'Annulée';
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className="bg-white rounded-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
          <div className="p-6 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <h2 className="text-xl font-semibold text-gray-900">{intervention.title}</h2>
                <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(intervention.status)}`}>
                  {getStatusText(intervention.status)}
                </span>
              </div>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          </div>

          <div className="p-6 space-y-6">
            {error && (
              <div className="p-3 bg-red-50 border border-red-200 rounded-lg flex items-center gap-2 text-red-600">
                <AlertCircle className="w-5 h-5 flex-shrink-0" />
                <p className="text-sm">{error}</p>
              </div>
            )}

            {/* Numéro de ticket */}
            <div>
              <div className="flex items-center gap-2 text-sm font-medium text-gray-500 mb-1">
                <FileText className="w-4 h-4" />
                Numéro de ticket
              </div>
              <p className="text-gray-900">#{intervention.ticketNumber}</p>
            </div>

            {/* Client & Location */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div>
                <div className="flex items-center gap-2 text-sm font-medium text-gray-500 mb-1">
                  <Building2 className="w-4 h-4" />
                  Client
                </div>
                <p className="text-gray-900">{intervention.client}</p>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2 text-sm font-medium text-gray-500 mb-1">
                    <MapPin className="w-4 h-4" />
                    Localisation
                  </div>
                  <button
                    onClick={() => openInMaps(intervention.location)}
                    className="text-blue-600 hover:text-blue-800 flex items-center gap-1 text-sm"
                  >
                    <Navigation className="w-4 h-4" />
                    Naviguer
                  </button>
                </div>
                <p className="text-gray-900">{intervention.location}</p>
              </div>
            </div>

            {/* Date & Time */}
            <div className="grid grid-cols-2 gap-6">
              <div>
                <div className="flex items-center gap-2 text-sm font-medium text-gray-500 mb-1">
                  <Clock className="w-4 h-4" />
                  Date
                </div>
                <p className="text-gray-900">{intervention.date}</p>
              </div>
              <div>
                <div className="flex items-center gap-2 text-sm font-medium text-gray-500 mb-1">
                  <Clock className="w-4 h-4" />
                  Heure
                </div>
                <p className="text-gray-900">
                  {intervention.time}
                  {intervention.isTimeStrict && (
                    <span className="ml-2 text-xs bg-red-100 text-red-600 px-2 py-1 rounded-full">
                      Horaire impératif
                    </span>
                  )}
                </p>
              </div>
            </div>

            {/* Description */}
            <div>
              <div className="flex items-center gap-2 text-sm font-medium text-gray-500 mb-1">
                <FileText className="w-4 h-4" />
                Description
              </div>
              <p className="text-gray-900 whitespace-pre-wrap">{intervention.description}</p>
            </div>

            {/* Numéros de tracking */}
            {intervention.trackingNumbers && intervention.trackingNumbers.length > 0 && (
              <div>
                <div className="flex items-center gap-2 text-sm font-medium text-gray-500 mb-2">
                  <Package className="w-4 h-4" />
                  Numéros de tracking
                </div>
                <div className="space-y-2">
                  {intervention.trackingNumbers.map((number, index) => (
                    <div key={index} className="flex items-center gap-2 bg-gray-50 p-2 rounded-lg">
                      <Package className="w-4 h-4 text-gray-400" />
                      <span className="text-sm text-gray-600">{number}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Action Buttons */}
            <div className="flex flex-wrap gap-2">
              {canEditIntervention && intervention.status !== 'completed' && (
                <button
                  onClick={() => setShowEditModal(true)}
                  className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-gray-50 text-gray-600 rounded-lg hover:bg-gray-100 transition-colors"
                >
                  <Edit className="w-5 h-5" />
                  Modifier
                </button>
              )}

              {intervention.status === 'pending' && isAssignedTechnician && (
                <button
                  onClick={startIntervention}
                  disabled={loading}
                  className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors disabled:opacity-50"
                >
                  <Play className="w-5 h-5" />
                  Démarrer l'intervention
                </button>
              )}

              {intervention.status === 'in_progress' && isAssignedTechnician && (
                <button
                  onClick={() => setShowCloseModal(true)}
                  className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-green-50 text-green-600 rounded-lg hover:bg-green-100 transition-colors"
                >
                  <CheckCircle className="w-5 h-5" />
                  Clôturer l'intervention
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {showEditModal && (
        <EditInterventionModal
          intervention={intervention}
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}

      {showCloseModal && (
        <CloseInterventionModal
          intervention={intervention}
          isOpen={showCloseModal}
          onClose={() => setShowCloseModal(false)}
        />
      )}
    </>
  );
}
import { collection, doc, setDoc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';

const COLLECTION = 'companies';

export async function getCompany(id: string) {
  try {
    const docRef = doc(db, COLLECTION, id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : null;
  } catch (error) {
    console.error('Error getting company:', error);
    throw error;
  }
}

export async function createCompany(userId: string, data: any) {
  try {
    // Create a new company document with a generated ID
    const companyRef = doc(collection(db, COLLECTION));
    
    // Clean up the data object to remove undefined values
    const cleanData = Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, any>);

    // Add company data
    await setDoc(companyRef, {
      ...cleanData,
      ownerId: userId,
      id: companyRef.id,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    });

    // Update user profile with company ID
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      company: companyRef.id
    });

    return companyRef.id;
  } catch (error) {
    console.error('Error creating company:', error);
    throw error;
  }
}

export async function updateCompanyProfile(id: string, data: any) {
  try {
    const docRef = doc(db, COLLECTION, id);
    const docSnap = await getDoc(docRef);
    
    // Clean up the data object to remove undefined values
    const cleanData = Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, any>);
    
    if (!docSnap.exists()) {
      // Create company if it doesn't exist
      await setDoc(docRef, {
        ...cleanData,
        id,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      });
    } else {
      // Update existing company
      await updateDoc(docRef, {
        ...cleanData,
        updatedAt: Timestamp.now()
      });
    }
  } catch (error) {
    console.error('Error updating company profile:', error);
    throw error;
  }
}

export async function uploadCompanyLogo(companyId: string, file: File): Promise<string> {
  try {
    // Create a reference with a timestamp to avoid name conflicts
    const storageRef = ref(storage, `companies/${companyId}/logo-${Date.now()}`);
    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(snapshot.ref);
    return url;
  } catch (error) {
    console.error('Error uploading company logo:', error);
    throw error;
  }
}
import { collection, addDoc, getDocs, query, where, updateDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import type { Contract } from '../../types';

const COLLECTION = 'contracts';

export async function getContracts(clientId?: string): Promise<Contract[]> {
  try {
    let q = query(collection(db, COLLECTION));
    
    if (clientId) {
      q = query(q, 
        where('clientId', '==', clientId),
        where('status', '==', 'active')
      );
    }
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Contract[];
  } catch (error) {
    console.error('Error fetching contracts:', error);
    throw error;
  }
}

export async function createContract(contract: Omit<Contract, 'id' | 'createdAt' | 'updatedAt'>) {
  try {
    const docRef = await addDoc(collection(db, COLLECTION), {
      ...contract,
      createdAt: Timestamp.now().toDate().toISOString(),
      updatedAt: Timestamp.now().toDate().toISOString(),
      status: 'active'
    });
    return docRef.id;
  } catch (error) {
    console.error('Error creating contract:', error);
    throw error;
  }
}

export async function updateContract(id: string, data: Partial<Contract>) {
  try {
    const docRef = doc(db, COLLECTION, id);
    await updateDoc(docRef, {
      ...data,
      updatedAt: Timestamp.now().toDate().toISOString()
    });
  } catch (error) {
    console.error('Error updating contract:', error);
    throw error;
  }
}

export async function getClientContracts(clientId: string): Promise<Contract[]> {
  return getContracts(clientId);
}
import React from 'react';
import { Clock, AlertCircle, CheckCircle } from 'lucide-react';
import { useInterventions } from '../../contexts/InterventionsContext';
import { useAuth } from '../../contexts/AuthContext';
import { DashboardWidget } from '../dashboard/DashboardWidget';
import { InterventionsMap } from '../maps/InterventionsMap';

export function TechnicianDashboard() {
  const { interventions } = useInterventions();
  const { userProfile } = useAuth();

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  // Filter today's interventions for this technician
  const todayInterventions = interventions.filter(i => 
    i.date === today && i.mainTechnicianId === userProfile?.technicianId
  );

  // Calculate stats
  const stats = {
    notStarted: todayInterventions.filter(i => i.status === 'pending').length,
    inProgress: todayInterventions.filter(i => i.status === 'in_progress').length,
    completed: todayInterventions.filter(i => i.status === 'completed').length,
    total: todayInterventions.length || 1 // Prevent division by zero
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <DashboardWidget
          title="NON COMMENCÉES"
          count={stats.notStarted}
          total={stats.total}
          icon={Clock}
          iconColor="text-orange-600"
          progressColor="stroke-orange-500"
        />
        <DashboardWidget
          title="NON CLÔTURÉES"
          count={stats.inProgress}
          total={stats.total}
          icon={AlertCircle}
          iconColor="text-blue-600"
          progressColor="stroke-blue-500"
        />
        <DashboardWidget
          title="TERMINÉES"
          count={stats.completed}
          total={stats.total}
          icon={CheckCircle}
          iconColor="text-emerald-600"
          progressColor="stroke-emerald-500"
        />
      </div>

      {/* Map with today's interventions */}
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900">Carte des interventions du jour</h2>
        </div>
        <div className="h-[400px]">
          <InterventionsMap
            interventions={todayInterventions}
            showRoute={true}
          />
        </div>
      </div>
    </div>
  );
}
import React, { useState } from 'react';
import { FileText, Plus, Euro, Clock, Calculator, Search, Edit2 } from 'lucide-react';
import { useClients } from '../contexts/ClientsContext';
import { useContracts } from '../contexts/ContractsContext';
import { PageHeader } from '../components/layout/PageHeader';
import { CreateContractModal } from '../components/contracts/CreateContractModal';
import { EditContractModal } from '../components/contracts/EditContractModal';
import type { Contract } from '../types';

export function Contracts() {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedContract, setSelectedContract] = useState<Contract | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { clients } = useClients();
  const { contracts, loading, error } = useContracts();

  const getClient = (clientId: string) => {
    return clients.find(c => c.id === clientId);
  };

  const getClientName = (clientId: string) => {
    const client = getClient(clientId);
    return client?.type === 'company' ? client.name : client?.contactName || client?.name || 'Client inconnu';
  };

  const filteredContracts = contracts.filter(contract => {
    const search = searchTerm.toLowerCase();
    const clientName = getClientName(contract.clientId).toLowerCase();
    const contractName = contract.name.toLowerCase();
    
    return clientName.includes(search) || contractName.includes(search);
  });

  return (
    <div className="space-y-6">
      <PageHeader
        title="Contrats"
        subtitle="Gérez les contrats et tarifs des interventions"
        icon={FileText}
        gradient="purple"
        actions={
          <button
            onClick={() => setIsCreateModalOpen(true)}
            className="bg-white/90 backdrop-blur-sm text-purple-600 px-4 py-2 rounded-lg hover:bg-white transition-colors flex items-center gap-2 shadow-sm"
          >
            <Plus className="w-4 h-4" />
            Nouveau contrat
          </button>
        }
      />

      {/* Filtres */}
      <div className="bg-white p-4 rounded-xl shadow-sm">
        <div className="relative">
          <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
          <input
            type="search"
            placeholder="Rechercher par nom du contrat ou client..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
          />
        </div>
      </div>

      {/* Liste des contrats */}
      {loading ? (
        <div className="text-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600 mx-auto"></div>
          <p className="mt-4 text-gray-500">Chargement des contrats...</p>
        </div>
      ) : error ? (
        <div className="text-center py-12 text-red-600">
          {error}
        </div>
      ) : filteredContracts.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-xl">
          <p className="text-gray-500">Aucun contrat trouvé</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredContracts.map((contract) => {
            const client = getClient(contract.clientId);
            return (
              <div
                key={contract.id}
                className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all cursor-pointer"
                onClick={() => setSelectedContract(contract)}
              >
                <div className="flex items-start justify-between mb-4">
                  <div className="flex items-center gap-3">
                    <div className="w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center overflow-hidden">
                      {client?.logo ? (
                        <img 
                          src={client.logo} 
                          alt={client.name}
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <FileText className="w-6 h-6 text-purple-600" />
                      )}
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">{contract.name}</h3>
                      <p className="text-sm text-gray-500">{getClientName(contract.clientId)}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedContract(contract);
                      }}
                      className="p-2 text-gray-400 hover:text-purple-600 transition-colors"
                    >
                      <Edit2 className="w-5 h-5" />
                    </button>
                    {contract.isSubcontractor && (
                      <span className="px-2 py-1 bg-purple-100 text-purple-800 rounded-full text-xs font-medium">
                        Sous-traitant
                      </span>
                    )}
                  </div>
                </div>

                <div className="space-y-3">
                  {(contract.startDate || contract.endDate) && (
                    <div className="flex items-center gap-2 text-sm text-gray-600">
                      <Clock className="w-4 h-4" />
                      <span>
                        {contract.startDate && new Date(contract.startDate).toLocaleDateString()}
                        {contract.endDate && ` - ${new Date(contract.endDate).toLocaleDateString()}`}
                      </span>
                    </div>
                  )}

                  <div className="flex items-center justify-between text-sm">
                    <div className="flex items-center gap-2 text-gray-600">
                      <Euro className="w-4 h-4" />
                      <span>Prix de base: {contract.basePrice}€</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-between text-sm">
                    <div className="flex items-center gap-2 text-gray-600">
                      <Clock className="w-4 h-4" />
                      <span>{contract.baseHours}h incluses</span>
                    </div>
                    <span className="text-gray-600">
                      +{contract.overtimeRate}€/h sup.
                    </span>
                  </div>

                  {contract.isSubcontractor && (
                    <div className="mt-4 p-3 bg-purple-50 rounded-lg space-y-2">
                      <div className="flex items-center justify-between text-sm">
                        <span className="text-purple-700">Taux sous-traitant</span>
                        <span className="font-medium text-purple-700">{contract.subcontractorRate}€/h</span>
                      </div>
                      <div className="flex items-center justify-between text-sm">
                        <span className="text-purple-700">Taux sup. sous-traitant</span>
                        <span className="font-medium text-purple-700">{contract.subcontractorOvertimeRate}€/h</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* Modals */}
      <CreateContractModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />

      {selectedContract && (
        <EditContractModal
          contract={selectedContract}
          isOpen={!!selectedContract}
          onClose={() => setSelectedContract(null)}
        />
      )}
    </div>
  );
}
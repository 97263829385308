import React from 'react';
import { User, Phone, Mail, Briefcase, Edit2 } from 'lucide-react';
import { Technician, TechnicianStatus } from '../../types';

interface TechniciansListProps {
  technicians: Technician[];
  onEdit: (technician: Technician) => void;
}

export function TechniciansList({ technicians, onEdit }: TechniciansListProps) {
  const getStatusColor = (status: TechnicianStatus) => {
    switch (status) {
      case 'available':
        return 'bg-green-100 text-green-800';
      case 'busy':
        return 'bg-yellow-100 text-yellow-800';
      case 'off':
        return 'bg-gray-100 text-gray-800';
      case 'vacation':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusText = (status: TechnicianStatus) => {
    switch (status) {
      case 'available':
        return 'Disponible';
      case 'busy':
        return 'Occupé';
      case 'off':
        return 'Indisponible';
      case 'vacation':
        return 'En congé';
      default:
        return status;
    }
  };

  if (technicians.length === 0) {
    return (
      <div className="text-center py-12 bg-white rounded-xl">
        <p className="text-gray-500">Aucun technicien trouvé</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {technicians.map((technician) => (
        <div
          key={technician.id}
          className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
        >
          <div className="flex items-start gap-4">
            <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center flex-shrink-0">
              {technician.avatar ? (
                <img
                  src={technician.avatar}
                  alt={technician.name}
                  className="w-full h-full rounded-lg object-cover"
                />
              ) : (
                <User className="w-6 h-6 text-blue-600" />
              )}
            </div>
            <div className="flex-1">
              <div className="flex items-center justify-between">
                <h3 className="font-medium text-gray-900">{technician.name}</h3>
                <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(technician.status)}`}>
                  {getStatusText(technician.status)}
                </span>
              </div>
              
              <div className="mt-2 space-y-1">
                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <Phone className="w-4 h-4" />
                  {technician.phone}
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <Mail className="w-4 h-4" />
                  {technician.email}
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <Briefcase className="w-4 h-4" />
                  {technician.skills.join(', ')}
                </div>
              </div>

              <button
                onClick={() => onEdit(technician)}
                className="mt-4 w-full px-4 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors flex items-center justify-center gap-2"
              >
                <Edit2 className="w-4 h-4" />
                Modifier
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
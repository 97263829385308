import React from 'react';
import { LucideIcon } from 'lucide-react';
import { CircularProgress } from './CircularProgress';

interface DashboardWidgetProps {
  title: string;
  count: number;
  total: number;
  icon: LucideIcon;
  iconColor: string;
  progressColor: string;
}

export function DashboardWidget({ 
  title, 
  count, 
  total, 
  icon: Icon,
  iconColor,
  progressColor
}: DashboardWidgetProps) {
  const percentage = total > 0 ? (count / total) * 100 : 0;

  return (
    <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition-shadow">
      <div className="flex flex-col h-full">
        <div className="flex items-center gap-3 mb-4">
          <Icon className={`w-5 h-5 ${iconColor}`} />
          <p className="text-sm font-medium text-gray-500">{title}</p>
        </div>
        <div className="flex items-center justify-between">
          <p className="text-4xl font-bold text-gray-900">{count}</p>
          <div>
            <CircularProgress
              value={percentage}
              color={progressColor}
              size={80}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
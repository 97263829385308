import { Contract } from '../../types';

export interface EmailTemplate {
  subject: string;
  body: string;
}

export const emailTemplates = {
  contractUpdated: (contract: Contract, clientName: string): EmailTemplate => ({
    subject: 'Modification de votre contrat - Action requise',
    body: `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h1 style="color: #6366f1;">Modification de votre contrat</h1>
        <p>Bonjour ${clientName},</p>
        <p>Des modifications ont été apportées à votre contrat "${contract.name}" et nécessitent votre validation.</p>
        
        <div style="background-color: #f3f4f6; padding: 20px; border-radius: 8px; margin: 20px 0;">
          <h2 style="color: #4f46e5; margin-top: 0;">Modifications apportées :</h2>
          <ul style="list-style-type: none; padding: 0;">
            ${contract.startDate ? `<li>Date de début : ${new Date(contract.startDate).toLocaleDateString()}</li>` : ''}
            ${contract.endDate ? `<li>Date de fin : ${new Date(contract.endDate).toLocaleDateString()}</li>` : ''}
            <li>Prix de base : ${contract.basePrice}€</li>
            <li>Heures incluses : ${contract.baseHours}h</li>
            <li>Taux horaire supplémentaire : ${contract.overtimeRate}€/h</li>
            ${contract.supportPhone ? `<li>Téléphone support : ${contract.supportPhonePrefix} ${contract.supportPhone}</li>` : ''}
          </ul>
        </div>

        <p>Veuillez vous connecter à votre espace client pour examiner et valider ces modifications.</p>
        
        <div style="margin-top: 20px; padding: 20px; background-color: #fef3c7; border-radius: 8px;">
          <p style="color: #92400e; margin: 0;">
            Note : Les modifications ne seront appliquées qu'après votre validation.
          </p>
        </div>
      </div>
    `
  }),

  // ... autres templates existants ...
}
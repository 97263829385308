import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from './layout/Layout';
import { Dashboard } from '../pages/Dashboard';
import { TechnicianDashboard } from './technician/TechnicianDashboard';
import Interventions from '../pages/Interventions';
import { Technicians } from '../pages/Technicians';
import { Equipment } from '../pages/Equipment';
import { Accounting } from '../pages/Accounting';
import { Company } from '../pages/Company';
import { Help } from '../pages/Help';
import { Account } from '../pages/Account';
import { Clients } from '../pages/Clients';
import { ClientPortal } from '../pages/ClientPortal';
import { Projects } from '../pages/Projects';
import { Contracts } from '../pages/Contracts';
import { Sites } from '../pages/Sites';
import { useAuth } from '../contexts/AuthContext';

export function AuthenticatedApp() {
  const { userProfile } = useAuth();

  // Rediriger les clients vers leur portail
  if (userProfile?.role === 'client') {
    return (
      <Layout>
        <Routes>
          <Route path="/" element={<ClientPortal />} />
          <Route path="/dashboard" element={<ClientPortal />} />
          <Route path="/interventions" element={<Interventions />} />
          <Route path="/sites" element={<Sites />} />
          <Route path="/entreprise" element={<Company />} />
          <Route path="/comptabilite" element={<Accounting />} />
          <Route path="/compte" element={<Account />} />
          <Route path="/aide" element={<Help />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>
    );
  }

  // Interface technicien
  if (userProfile?.role === 'technician') {
    return (
      <Layout>
        <Routes>
          <Route path="/" element={<TechnicianDashboard />} />
          <Route path="/dashboard" element={<TechnicianDashboard />} />
          <Route path="/interventions" element={<Interventions />} />
          <Route path="/sites" element={<Sites />} />
          <Route path="/compte" element={<Account />} />
          <Route path="/aide" element={<Help />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>
    );
  }

  // Interface admin/dispatcher
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/interventions" element={<Interventions />} />
        <Route path="/sites" element={<Sites />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/contracts" element={<Contracts />} />
        <Route path="/technicians" element={<Technicians />} />
        <Route path="/equipment" element={<Equipment />} />
        <Route path="/comptabilite" element={<Accounting />} />
        <Route path="/entreprise" element={<Company />} />
        <Route path="/compte" element={<Account />} />
        <Route path="/aide" element={<Help />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Layout>
  );
}
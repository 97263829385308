import React, { useState, useEffect } from 'react';
import { Filter, Search, Plus, FileText, MapPin, Calendar, Clock, Euro, AlertCircle } from 'lucide-react';
import { useInterventions } from '../contexts/InterventionsContext';
import { useAuth } from '../contexts/AuthContext';
import { InterventionDetailsModal } from '../components/interventions/InterventionDetailsModal';
import { CreateInterventionModal } from '../components/interventions/CreateInterventionModal';
import { OverdueInterventionsModal } from '../components/interventions/OverdueInterventionsModal';
import { PageHeader } from '../components/layout/PageHeader';
import type { Intervention } from '../types';

export default function Interventions() {
  const { interventions, loading, error, filterStatus, setFilterStatus, refreshInterventions } = useInterventions();
  const { userProfile } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIntervention, setSelectedIntervention] = useState<Intervention | null>(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [showOverdueModal, setShowOverdueModal] = useState(false);

  // Rafraîchir les données toutes les 30 secondes
  useEffect(() => {
    const interval = setInterval(() => {
      refreshInterventions();
    }, 30000);

    return () => clearInterval(interval);
  }, [refreshInterventions]);

  // Filtrer les interventions selon le rôle
  const filteredInterventions = interventions.filter(intervention => {
    // Si c'est un technicien, ne montrer que ses interventions
    if (userProfile?.role === 'technician') {
      if (intervention.mainTechnicianId !== userProfile.technicianId) {
        return false;
      }
    }

    const matchesSearch = 
      intervention.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      intervention.client.toLowerCase().includes(searchTerm.toLowerCase()) ||
      intervention.location.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = filterStatus === 'all' || intervention.status === filterStatus;
    return matchesSearch && matchesStatus;
  });

  // Trouver les interventions non clôturées d'hier ou avant
  const overdueInterventions = interventions.filter(i => {
    const interventionDate = new Date(i.date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return interventionDate < today && i.status !== 'completed';
  });

  return (
    <div className="space-y-6">
      <PageHeader
        title={userProfile?.role === 'technician' ? 'Mes Interventions' : 'Interventions'}
        subtitle="Gérez et suivez vos interventions"
        icon={FileText}
        gradient="orange"
        actions={
          <div className="flex items-center gap-2">
            <button
              onClick={() => setShowOverdueModal(true)}
              className="bg-white/90 backdrop-blur-sm text-red-600 px-4 py-2 rounded-lg hover:bg-white transition-colors flex items-center gap-2 shadow-sm"
            >
              <AlertCircle className="w-4 h-4" />
              Non clôturées ({overdueInterventions.length})
            </button>
            {userProfile?.role !== 'technician' && (
              <button
                onClick={() => setIsCreateModalOpen(true)}
                className="bg-white/90 backdrop-blur-sm text-orange-600 px-4 py-2 rounded-lg hover:bg-white transition-colors flex items-center gap-2 shadow-sm"
              >
                <Plus className="w-4 h-4" />
                Nouvelle intervention
              </button>
            )}
          </div>
        }
      />

      {/* Liste des interventions */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredInterventions.length === 0 ? (
          <div className="col-span-full text-center py-12 bg-white rounded-xl">
            <p className="text-gray-500">Aucune intervention trouvée</p>
          </div>
        ) : (
          filteredInterventions.map((intervention) => (
            <div
              key={intervention.id}
              onClick={() => setSelectedIntervention(intervention)}
              className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all cursor-pointer group"
            >
              <div className="space-y-4">
                <div>
                  <p className="text-sm text-gray-500">#{intervention.ticketNumber}</p>
                  <h3 className="font-medium text-gray-900 group-hover:text-orange-600 transition-colors">
                    {intervention.title}
                  </h3>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-sm text-gray-600">
                    <MapPin className="w-4 h-4" />
                    {intervention.location}
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2 text-sm text-gray-600">
                      <Calendar className="w-4 h-4" />
                      {intervention.date}
                    </div>
                    <div className="flex items-center gap-2 text-sm text-gray-600">
                      <Clock className="w-4 h-4" />
                      {intervention.time}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Modals */}
      {selectedIntervention && (
        <InterventionDetailsModal
          intervention={selectedIntervention}
          isOpen={!!selectedIntervention}
          onClose={() => setSelectedIntervention(null)}
        />
      )}

      {userProfile?.role !== 'technician' && (
        <CreateInterventionModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
        />
      )}

      <OverdueInterventionsModal
        isOpen={showOverdueModal}
        onClose={() => setShowOverdueModal(false)}
        interventions={overdueInterventions}
        onSelectIntervention={setSelectedIntervention}
      />
    </div>
  );
}
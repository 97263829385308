import React, { useState } from 'react';
import { X, Clock, Upload, AlertCircle, FileText, Plus } from 'lucide-react';
import { useInterventions } from '../../contexts/InterventionsContext';
import { SignaturePad } from './SignaturePad';
import type { Intervention } from '../../types';

interface CloseInterventionModalProps {
  intervention: Intervention;
  isOpen: boolean;
  onClose: () => void;
}

export function CloseInterventionModal({ intervention, isOpen, onClose }: CloseInterventionModalProps) {
  const { closeInterventionWithDetails } = useInterventions();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [formData, setFormData] = useState({
    description: '',
    startTime: localStorage.getItem(`intervention-${intervention.id}-start`) || '',
    endTime: new Date().toISOString(),
    replacedParts: [] as { name: string; serialNumber: string; photos?: string[] }[],
    technicianSignature: '',
    technicianName: intervention.mainTechnicianId || '',
    clientSignature: '',
    clientName: intervention.client || '',
    photos: [] as File[],
    attachments: [] as File[],
    needsFollowUp: false,
    followUpDescription: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.technicianSignature || !formData.clientSignature) {
      setError('Les signatures sont requises');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await closeInterventionWithDetails(intervention.id, formData);
      // Supprimer l'heure de début du localStorage
      localStorage.removeItem(`intervention-${intervention.id}-start`);
      onClose();
    } catch (err) {
      console.error('Error closing intervention:', err);
      setError('Erreur lors de la clôture de l\'intervention');
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (type: 'photos' | 'attachments') => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      setFormData(prev => ({
        ...prev,
        [type]: [...prev[type], ...files]
      }));
    }
  };

  const removeFile = (type: 'photos' | 'attachments', index: number) => {
    setFormData(prev => ({
      ...prev,
      [type]: prev[type].filter((_, i) => i !== index)
    }));
  };

  const addReplacedPart = () => {
    setFormData(prev => ({
      ...prev,
      replacedParts: [...prev.replacedParts, { name: '', serialNumber: '' }]
    }));
  };

  const updateReplacedPart = (index: number, field: 'name' | 'serialNumber', value: string) => {
    setFormData(prev => ({
      ...prev,
      replacedParts: prev.replacedParts.map((part, i) => 
        i === index ? { ...part, [field]: value } : part
      )
    }));
  };

  const removeReplacedPart = (index: number) => {
    setFormData(prev => ({
      ...prev,
      replacedParts: prev.replacedParts.filter((_, i) => i !== index)
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-900">Clôturer l'intervention</h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {error && (
            <div className="p-3 bg-red-50 border border-red-200 rounded-lg flex items-center gap-2 text-red-600">
              <AlertCircle className="w-5 h-5 flex-shrink-0" />
              <p className="text-sm">{error}</p>
            </div>
          )}

          {/* Heures */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="startTime" className="block text-sm font-medium text-gray-700 mb-1">
                Heure de début
              </label>
              <div className="relative">
                <Clock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <input
                  type="time"
                  id="startTime"
                  required
                  value={formData.startTime.split('T')[1]?.slice(0, 5) || ''}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    startTime: `${new Date().toISOString().split('T')[0]}T${e.target.value}:00`
                  }))}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div>
              <label htmlFor="endTime" className="block text-sm font-medium text-gray-700 mb-1">
                Heure de fin
              </label>
              <div className="relative">
                <Clock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <input
                  type="time"
                  id="endTime"
                  required
                  value={formData.endTime.split('T')[1]?.slice(0, 5) || ''}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    endTime: `${new Date().toISOString().split('T')[0]}T${e.target.value}:00`
                  }))}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          {/* Description */}
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              Description des travaux effectués
            </label>
            <textarea
              id="description"
              required
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              rows={4}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Pièces remplacées */}
          <div>
            <div className="flex items-center justify-between mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Pièces remplacées
              </label>
              <button
                type="button"
                onClick={addReplacedPart}
                className="text-sm text-blue-600 hover:text-blue-700 flex items-center gap-1"
              >
                <Plus className="w-4 h-4" />
                Ajouter une pièce
              </button>
            </div>
            <div className="space-y-4">
              {formData.replacedParts.map((part, index) => (
                <div key={index} className="flex items-center gap-4">
                  <input
                    type="text"
                    placeholder="Nom de la pièce"
                    value={part.name}
                    onChange={(e) => updateReplacedPart(index, 'name', e.target.value)}
                    className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                  <input
                    type="text"
                    placeholder="Numéro de série"
                    value={part.serialNumber}
                    onChange={(e) => updateReplacedPart(index, 'serialNumber', e.target.value)}
                    className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                  <button
                    type="button"
                    onClick={() => removeReplacedPart(index)}
                    className="text-red-600 hover:text-red-700"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Photos */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Photos
            </label>
            <div className="space-y-2">
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileChange('photos')}
                className="hidden"
                id="photos"
              />
              <label
                htmlFor="photos"
                className="inline-flex items-center gap-2 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer"
              >
                <Upload className="w-4 h-4" />
                Ajouter des photos
              </label>
              {formData.photos.length > 0 && (
                <div className="grid grid-cols-2 gap-2">
                  {formData.photos.map((file, index) => (
                    <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded-lg">
                      <span className="text-sm text-gray-600 truncate">{file.name}</span>
                      <button
                        type="button"
                        onClick={() => removeFile('photos', index)}
                        className="text-red-600 hover:text-red-700"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Pièces jointes */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Pièces jointes
            </label>
            <div className="space-y-2">
              <input
                type="file"
                multiple
                onChange={handleFileChange('attachments')}
                className="hidden"
                id="attachments"
              />
              <label
                htmlFor="attachments"
                className="inline-flex items-center gap-2 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer"
              >
                <Upload className="w-4 h-4" />
                Ajouter des fichiers
              </label>
              {formData.attachments.length > 0 && (
                <div className="space-y-2">
                  {formData.attachments.map((file, index) => (
                    <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded-lg">
                      <span className="text-sm text-gray-600">{file.name}</span>
                      <button
                        type="button"
                        onClick={() => removeFile('attachments', index)}
                        className="text-red-600 hover:text-red-700"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Suivi nécessaire */}
          <div>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.needsFollowUp}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  needsFollowUp: e.target.checked
                }))}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="text-sm text-gray-600">Un suivi est nécessaire</span>
            </label>
            {formData.needsFollowUp && (
              <textarea
                value={formData.followUpDescription}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  followUpDescription: e.target.value
                }))}
                placeholder="Description du suivi nécessaire..."
                className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                rows={3}
              />
            )}
          </div>

          {/* Signatures */}
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Signature du technicien
              </label>
              <SignaturePad
                value={formData.technicianSignature}
                onChange={(value) => setFormData(prev => ({
                  ...prev,
                  technicianSignature: value
                }))}
              />
              <input
                type="text"
                placeholder="Nom du technicien"
                value={formData.technicianName}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  technicianName: e.target.value
                }))}
                className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Signature du client
              </label>
              <SignaturePad
                value={formData.clientSignature}
                onChange={(value) => setFormData(prev => ({
                  ...prev,
                  clientSignature: value
                }))}
              />
              <input
                type="text"
                placeholder="Nom du client"
                value={formData.clientName}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  clientName: e.target.value
                }))}
                className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
            >
              {loading ? (
                <>
                  <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  Clôture...
                </>
              ) : (
                'Clôturer l\'intervention'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { Building2, Plus, Search, MapPin, Phone, Mail, Download, Upload, Clock, User } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { getSites } from '../lib/firebase/sites';
import { PageHeader } from '../components/layout/PageHeader';
import { CreateSiteModal } from '../components/sites/CreateSiteModal';
import { EditSiteModal } from '../components/sites/EditSiteModal';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import type { Site } from '../types';

export function Sites() {
  const { userProfile } = useAuth();
  const [sites, setSites] = useState<Site[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);
  const [importTemplate, setImportTemplate] = useState<Partial<Site> | null>(null);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        setLoading(true);
        setError(null);

        if (!userProfile?.company) {
          setError('Erreur: Client non identifié');
          return;
        }

        const data = await getSites(userProfile.company);
        setSites(data);
      } catch (err) {
        console.error('Error fetching sites:', err);
        setError('Erreur lors du chargement des sites');
      } finally {
        setLoading(false);
      }
    };

    if (userProfile?.company) {
      fetchSites();
    }
  }, [userProfile]);

  const filteredSites = sites.filter(site => {
    const search = searchTerm.toLowerCase();
    const siteName = site.name.toLowerCase();
    const siteAddress = site.address.toLowerCase();
    const storeCode = site.storeCode?.toLowerCase() || '';
    
    return siteName.includes(search) || 
           siteAddress.includes(search) ||
           storeCode.includes(search);
  });

  const handleExportExcel = () => {
    // Préparer les données pour l'export
    const exportData = sites.map(site => ({
      'Nom du site': site.name,
      'Code magasin': site.storeCode || '',
      'Adresse': site.address,
      'Contact': site.contactName || '',
      'Téléphone': site.contactPhone || '',
      'Email': site.contactEmail || '',
      'Notes': site.notes || '',
      'Lundi Ouverture': site.openingHours.monday.open,
      'Lundi Fermeture': site.openingHours.monday.close,
      'Lundi Après-midi Ouverture': site.openingHours.monday.afternoonOpen,
      'Lundi Après-midi Fermeture': site.openingHours.monday.afternoonClose,
      'Lundi Fermé': site.openingHours.monday.closed ? 'Oui' : 'Non',
      'Mardi Ouverture': site.openingHours.tuesday.open,
      'Mardi Fermeture': site.openingHours.tuesday.close,
      'Mardi Après-midi Ouverture': site.openingHours.tuesday.afternoonOpen,
      'Mardi Après-midi Fermeture': site.openingHours.tuesday.afternoonClose,
      'Mardi Fermé': site.openingHours.tuesday.closed ? 'Oui' : 'Non',
      'Mercredi Ouverture': site.openingHours.wednesday.open,
      'Mercredi Fermeture': site.openingHours.wednesday.close,
      'Mercredi Après-midi Ouverture': site.openingHours.wednesday.afternoonOpen,
      'Mercredi Après-midi Fermeture': site.openingHours.wednesday.afternoonClose,
      'Mercredi Fermé': site.openingHours.wednesday.closed ? 'Oui' : 'Non',
      'Jeudi Ouverture': site.openingHours.thursday.open,
      'Jeudi Fermeture': site.openingHours.thursday.close,
      'Jeudi Après-midi Ouverture': site.openingHours.thursday.afternoonOpen,
      'Jeudi Après-midi Fermeture': site.openingHours.thursday.afternoonClose,
      'Jeudi Fermé': site.openingHours.thursday.closed ? 'Oui' : 'Non',
      'Vendredi Ouverture': site.openingHours.friday.open,
      'Vendredi Fermeture': site.openingHours.friday.close,
      'Vendredi Après-midi Ouverture': site.openingHours.friday.afternoonOpen,
      'Vendredi Après-midi Fermeture': site.openingHours.friday.afternoonClose,
      'Vendredi Fermé': site.openingHours.friday.closed ? 'Oui' : 'Non',
      'Samedi Ouverture': site.openingHours.saturday.open,
      'Samedi Fermeture': site.openingHours.saturday.close,
      'Samedi Après-midi Ouverture': site.openingHours.saturday.afternoonOpen,
      'Samedi Après-midi Fermeture': site.openingHours.saturday.afternoonClose,
      'Samedi Fermé': site.openingHours.saturday.closed ? 'Oui' : 'Non',
      'Dimanche Ouverture': site.openingHours.sunday.open,
      'Dimanche Fermeture': site.openingHours.sunday.close,
      'Dimanche Après-midi Ouverture': site.openingHours.sunday.afternoonOpen,
      'Dimanche Après-midi Fermeture': site.openingHours.sunday.afternoonClose,
      'Dimanche Fermé': site.openingHours.sunday.closed ? 'Oui' : 'Non'
    }));

    // Créer un classeur Excel
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sites');
    
    // Générer le fichier Excel
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, 'sites.xlsx');
  };

  const handleImportExcel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        // Créer un template à partir de la première ligne
        if (jsonData.length > 0) {
          const template: Partial<Site> = {
            name: '',
            storeCode: '',
            address: '',
            contactName: '',
            contactPhone: '',
            contactEmail: '',
            notes: '',
            openingHours: {
              monday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: false },
              tuesday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: false },
              wednesday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: false },
              thursday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: false },
              friday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: false },
              saturday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: false },
              sunday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: true }
            }
          };
          setImportTemplate(template);
          setIsCreateModalOpen(true);
        }
      } catch (err) {
        console.error('Erreur lors de l\'import:', err);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="space-y-6">
      <PageHeader
        title="Sites"
        subtitle="Gérez les sites d'intervention"
        icon={Building2}
        gradient="teal"
        actions={
          <div className="flex items-center gap-2">
            <input
              type="file"
              accept=".xlsx,.xls"
              onChange={handleImportExcel}
              className="hidden"
              id="import-excel"
            />
            <label
              htmlFor="import-excel"
              className="bg-white/90 backdrop-blur-sm text-teal-600 px-4 py-2 rounded-lg hover:bg-white transition-colors flex items-center gap-2 shadow-sm cursor-pointer"
            >
              <Upload className="w-4 h-4" />
              Import Excel
            </label>
            <button
              onClick={handleExportExcel}
              className="bg-white/90 backdrop-blur-sm text-teal-600 px-4 py-2 rounded-lg hover:bg-white transition-colors flex items-center gap-2 shadow-sm"
            >
              <Download className="w-4 h-4" />
              Export Excel
            </button>
            <button
              onClick={() => setIsCreateModalOpen(true)}
              className="bg-white/90 backdrop-blur-sm text-teal-600 px-4 py-2 rounded-lg hover:bg-white transition-colors flex items-center gap-2 shadow-sm"
            >
              <Plus className="w-4 h-4" />
              Nouveau site
            </button>
          </div>
        }
      />

      {/* Filtres */}
      <div className="bg-white p-4 rounded-xl shadow-sm">
        <div className="relative">
          <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
          <input
            type="search"
            placeholder="Rechercher un site..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
          />
        </div>
      </div>

      {/* Liste des sites */}
      {loading ? (
        <div className="text-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-teal-600 mx-auto"></div>
          <p className="mt-4 text-gray-500">Chargement des sites...</p>
        </div>
      ) : error ? (
        <div className="text-center py-12 text-red-600">
          {error}
        </div>
      ) : filteredSites.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-xl">
          <p className="text-gray-500">Aucun site trouvé</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredSites.map((site) => (
            <div
              key={site.id}
              onClick={() => setSelectedSite(site)}
              className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all cursor-pointer"
            >
              <div className="flex items-start justify-between mb-4">
                <div className="flex items-center gap-3">
                  <div className="w-12 h-12 bg-teal-100 rounded-lg flex items-center justify-center">
                    <Building2 className="w-6 h-6 text-teal-600" />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900">{site.name}</h3>
                    {site.storeCode && (
                      <p className="text-sm text-gray-500">Code: {site.storeCode}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="space-y-3">
                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <MapPin className="w-4 h-4" />
                  {site.address}
                </div>

                {site.contactName && (
                  <div className="flex items-center gap-2 text-sm text-gray-600">
                    <User className="w-4 h-4" />
                    {site.contactName}
                  </div>
                )}

                {site.contactPhone && (
                  <div className="flex items-center gap-2 text-sm text-gray-600">
                    <Phone className="w-4 h-4" />
                    {site.contactPhone}
                  </div>
                )}

                {site.contactEmail && (
                  <div className="flex items-center gap-2 text-sm text-gray-600">
                    <Mail className="w-4 h-4" />
                    {site.contactEmail}
                  </div>
                )}

                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <Clock className="w-4 h-4" />
                  {site.openingHours.monday.closed ? 'Fermé' : `${site.openingHours.monday.open} - ${site.openingHours.monday.close}`}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Modals */}
      <CreateSiteModal
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
          setImportTemplate(null);
        }}
        template={importTemplate}
      />

      {selectedSite && (
        <EditSiteModal
          site={selectedSite}
          isOpen={!!selectedSite}
          onClose={() => setSelectedSite(null)}
        />
      )}
    </div>
  );
}
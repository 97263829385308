import { collection, addDoc, getDocs, query, orderBy, updateDoc, doc, deleteDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
import { Technician, TechnicianStatus } from '../../types';

const COLLECTION = 'technicians';

export async function getTechnicians(status?: TechnicianStatus) {
  try {
    let q = query(collection(db, COLLECTION), orderBy('name'));
    
    if (status) {
      q = query(q, where('status', '==', status));
    }
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Technician[];
  } catch (error) {
    console.error('Error fetching technicians:', error);
    throw error;
  }
}

export async function createTechnician(technician: Omit<Technician, 'id'>) {
  try {
    const docRef = await addDoc(collection(db, COLLECTION), technician);
    return docRef.id;
  } catch (error) {
    console.error('Error creating technician:', error);
    throw error;
  }
}

export async function updateTechnician(id: string, data: Partial<Technician>) {
  try {
    const docRef = doc(db, COLLECTION, id);
    await updateDoc(docRef, data);
  } catch (error) {
    console.error('Error updating technician:', error);
    throw error;
  }
}

export async function deleteTechnician(id: string) {
  try {
    await deleteDoc(doc(db, COLLECTION, id));
  } catch (error) {
    console.error('Error deleting technician:', error);
    throw error;
  }
}
import React, { useState } from 'react';
import { X, Building2, MapPin, Phone, Mail, User, Clock } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { createSite } from '../../lib/firebase/sites';
import type { Site, OpeningHours } from '../../types';

interface CreateSiteModalProps {
  isOpen: boolean;
  onClose: () => void;
  template?: Partial<Site>;
}

export function CreateSiteModal({ isOpen, onClose, template }: CreateSiteModalProps) {
  const { userProfile } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [formData, setFormData] = useState<Partial<Site>>(template || {
    name: '',
    storeCode: '',
    address: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    notes: '',
    openingHours: {
      monday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: false },
      tuesday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: false },
      wednesday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: false },
      thursday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: false },
      friday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: false },
      saturday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: false },
      sunday: { open: '', close: '', afternoonOpen: '', afternoonClose: '', closed: true }
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userProfile?.company) {
      setError('Erreur: Client non identifié');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await createSite({
        ...formData,
        clientId: userProfile.company,
        address: formData.address || ''
      } as Site);
      
      onClose();
    } catch (err) {
      console.error('Error creating site:', err);
      setError('Erreur lors de la création du site');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleHoursChange = (
    day: keyof Site['openingHours'],
    field: keyof OpeningHours,
    value: string | boolean
  ) => {
    setFormData(prev => ({
      ...prev,
      openingHours: {
        ...prev.openingHours,
        [day]: {
          ...prev.openingHours?.[day],
          [field]: value
        }
      }
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-900">Nouveau site</h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {error && (
            <div className="p-3 bg-red-50 border border-red-200 rounded-lg text-red-600 text-sm">
              {error}
            </div>
          )}

          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Nom du site
            </label>
            <div className="relative">
              <Building2 className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
              <input
                type="text"
                id="name"
                name="name"
                required
                value={formData.name}
                onChange={handleChange}
                className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
              />
            </div>
          </div>

          <div>
            <label htmlFor="storeCode" className="block text-sm font-medium text-gray-700 mb-1">
              Code magasin (optionnel)
            </label>
            <input
              type="text"
              id="storeCode"
              name="storeCode"
              value={formData.storeCode}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
            />
          </div>

          <div>
            <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
              Adresse
            </label>
            <div className="relative">
              <MapPin className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
              <input
                type="text"
                id="address"
                name="address"
                required
                value={formData.address}
                onChange={handleChange}
                className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                placeholder="Adresse complète"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="contactName" className="block text-sm font-medium text-gray-700 mb-1">
                Nom du contact
              </label>
              <div className="relative">
                <User className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <input
                  type="text"
                  id="contactName"
                  name="contactName"
                  value={formData.contactName}
                  onChange={handleChange}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                />
              </div>
            </div>

            <div>
              <label htmlFor="contactPhone" className="block text-sm font-medium text-gray-700 mb-1">
                Téléphone du contact
              </label>
              <div className="relative">
                <Phone className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <input
                  type="tel"
                  id="contactPhone"
                  name="contactPhone"
                  value={formData.contactPhone}
                  onChange={handleChange}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                />
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700 mb-1">
              Email du contact
            </label>
            <div className="relative">
              <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
              <input
                type="email"
                id="contactEmail"
                name="contactEmail"
                value={formData.contactEmail}
                onChange={handleChange}
                className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Horaires d'ouverture
            </label>
            <div className="space-y-4">
              {Object.entries(formData.openingHours || {}).map(([day, hours]) => (
                <div key={day} className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center justify-between mb-2">
                    <span className="font-medium text-gray-700 capitalize">
                      {day === 'monday' ? 'Lundi' :
                       day === 'tuesday' ? 'Mardi' :
                       day === 'wednesday' ? 'Mercredi' :
                       day === 'thursday' ? 'Jeudi' :
                       day === 'friday' ? 'Vendredi' :
                       day === 'saturday' ? 'Samedi' : 'Dimanche'}
                    </span>
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={hours.closed}
                        onChange={(e) => handleHoursChange(
                          day as keyof Site['openingHours'],
                          'closed',
                          e.target.checked
                        )}
                        className="rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      />
                      <span className="text-sm text-gray-600">Fermé</span>
                    </label>
                  </div>

                  {!hours.closed && (
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <p className="text-sm text-gray-600">Matin</p>
                        <div className="flex items-center gap-2">
                          <div className="relative flex-1">
                            <Clock className="w-4 h-4 text-gray-400 absolute left-2 top-1/2 -translate-y-1/2" />
                            <input
                              type="time"
                              value={hours.open}
                              onChange={(e) => handleHoursChange(
                                day as keyof Site['openingHours'],
                                'open',
                                e.target.value
                              )}
                              className="pl-8 w-full px-3 py-1.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500 text-sm"
                            />
                          </div>
                          <span className="text-gray-500">-</span>
                          <div className="relative flex-1">
                            <Clock className="w-4 h-4 text-gray-400 absolute left-2 top-1/2 -translate-y-1/2" />
                            <input
                              type="time"
                              value={hours.close}
                              onChange={(e) => handleHoursChange(
                                day as keyof Site['openingHours'],
                                'close',
                                e.target.value
                              )}
                              className="pl-8 w-full px-3 py-1.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500 text-sm"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-y-2">
                        <p className="text-sm text-gray-600">Après-midi</p>
                        <div className="flex items-center gap-2">
                          <div className="relative flex-1">
                            <Clock className="w-4 h-4 text-gray-400 absolute left-2 top-1/2 -translate-y-1/2" />
                            <input
                              type="time"
                              value={hours.afternoonOpen}
                              onChange={(e) => handleHoursChange(
                                day as keyof Site['openingHours'],
                                'afternoonOpen',
                                e.target.value
                              )}
                              className="pl-8 w-full px-3 py-1.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500 text-sm"
                            />
                          </div>
                          <span className="text-gray-500">-</span>
                          <div className="relative flex-1">
                            <Clock className="w-4 h-4 text-gray-400 absolute left-2 top-1/2 -translate-y-1/2" />
                            <input
                              type="time"
                              value={hours.afternoonClose}
                              onChange={(e) => handleHoursChange(
                                day as keyof Site['openingHours'],
                                'afternoonClose',
                                e.target.value
                              )}
                              className="pl-8 w-full px-3 py-1.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500 text-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div>
            <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">
              Notes
            </label>
            <textarea
              id="notes"
              name="notes"
              rows={3}
              value={formData.notes}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
            />
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-700 disabled:opacity-50"
            >
              {loading ? 'Création...' : 'Créer le site'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
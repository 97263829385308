import React, { useState } from 'react';
import { UserPlus, AlertCircle, Building2, Phone, Mail, Shield } from 'lucide-react';
import { createUser } from '../../lib/firebase/auth';
import type { UserRole } from '../../types';

interface SignupFormProps {
  onCancel: () => void;
}

export function SignupForm({ onCancel }: SignupFormProps) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    company: '',
    phone: '',
    role: 'client' as UserRole
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const availableRoles: { value: UserRole; label: string }[] = [
    { value: 'admin', label: 'Administrateur' },
    { value: 'dispatcher', label: 'Dispatcher' },
    { value: 'technician', label: 'Technicien' },
    { value: 'client', label: 'Client' },
    { value: 'subcontractor', label: 'Sous-traitant' },
    { value: 'administrative', label: 'Gestionnaire administratif' }
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (formData.password !== formData.confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return;
    }

    if (formData.password.length < 6) {
      setError('Le mot de passe doit contenir au moins 6 caractères');
      return;
    }

    setLoading(true);
    try {
      await createUser(formData.email, formData.password, formData.role, {
        name: formData.name,
        company: formData.company,
        phone: formData.phone
      });
    } catch (error: any) {
      console.error('Signup error:', error);
      setError(error.message || 'Erreur lors de l\'inscription');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Form content remains the same */}
    </form>
  );
}
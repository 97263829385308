import React, { createContext, useContext, useState, useEffect } from 'react';
import { Intervention, InterventionStatus } from '../types';
import { getInterventions, createIntervention, updateIntervention, deleteIntervention, closeIntervention } from '../lib/firebase/interventions';
import { useAuth } from './AuthContext';

interface CompletionDetails {
  description: string;
  startTime: string;
  endTime: string;
  replacedParts: Array<{
    name: string;
    serialNumber: string;
    photos?: string[];
  }>;
  technicianSignature: string;
  technicianName: string;
  clientSignature: string;
  clientName: string;
  photos: File[];
  attachments: File[];
  needsFollowUp: boolean;
  followUpDescription?: string;
}

interface InterventionsContextType {
  interventions: Intervention[];
  loading: boolean;
  error: string | null;
  filterStatus: InterventionStatus | 'all';
  setFilterStatus: (status: InterventionStatus | 'all') => void;
  createNewIntervention: (intervention: Omit<Intervention, 'id' | 'ticketNumber'>) => Promise<string>;
  updateInterventionStatus: (id: string, status: InterventionStatus) => Promise<void>;
  updateInterventionDetails: (id: string, data: Partial<Intervention>) => Promise<void>;
  closeInterventionWithDetails: (id: string, details: CompletionDetails) => Promise<void>;
  assignTechnician: (interventionId: string, technicianId: string) => Promise<void>;
  removeIntervention: (id: string) => Promise<void>;
  refreshInterventions: () => Promise<void>;
}

const InterventionsContext = createContext<InterventionsContextType | null>(null);

export function InterventionsProvider({ children }: { children: React.ReactNode }) {
  const { userProfile } = useAuth();
  const [interventions, setInterventions] = useState<Intervention[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterStatus, setFilterStatus] = useState<InterventionStatus | 'all'>('all');

  const fetchInterventions = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getInterventions(filterStatus === 'all' ? undefined : filterStatus);
      setInterventions(data);
    } catch (err) {
      setError('Erreur lors du chargement des interventions');
      console.error('Error fetching interventions:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInterventions();
  }, [filterStatus, userProfile]);

  const createNewIntervention = async (intervention: Omit<Intervention, 'id' | 'ticketNumber'>) => {
    const id = await createIntervention({
      ...intervention,
      createdBy: userProfile?.id || '',
    });
    await fetchInterventions();
    return id;
  };

  const updateInterventionStatus = async (id: string, status: InterventionStatus) => {
    await updateIntervention(id, { status });
    await fetchInterventions();
  };

  const updateInterventionDetails = async (id: string, data: Partial<Intervention>) => {
    await updateIntervention(id, data);
    await fetchInterventions();
  };

  const closeInterventionWithDetails = async (id: string, details: CompletionDetails) => {
    await closeIntervention(id, details);
    await fetchInterventions();
  };

  const assignTechnician = async (interventionId: string, technicianId: string) => {
    await updateIntervention(interventionId, {
      mainTechnicianId: technicianId,
      status: 'in_progress'
    });
    await fetchInterventions();
  };

  const removeIntervention = async (id: string) => {
    await deleteIntervention(id);
    await fetchInterventions();
  };

  const value = {
    interventions,
    loading,
    error,
    filterStatus,
    setFilterStatus,
    createNewIntervention,
    updateInterventionStatus,
    updateInterventionDetails,
    closeInterventionWithDetails,
    assignTechnician,
    removeIntervention,
    refreshInterventions: fetchInterventions
  };

  return (
    <InterventionsContext.Provider value={value}>
      {children}
    </InterventionsContext.Provider>
  );
}

export function useInterventions() {
  const context = useContext(InterventionsContext);
  if (!context) {
    throw new Error('useInterventions must be used within an InterventionsProvider');
  }
  return context;
}
import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { AlertCircle } from 'lucide-react';
import { Intervention } from '../../types';
import { useAuth } from '../../contexts/AuthContext';

interface InterventionsMapProps {
  interventions: Intervention[];
  onMarkerClick?: (intervention: Intervention) => void;
  showRoute?: boolean;
}

export function InterventionsMap({ interventions, onMarkerClick, showRoute = false }: InterventionsMapProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<google.maps.Map | null>(null);
  const markersRef = useRef<google.maps.Marker[]>([]);
  const directionsRendererRef = useRef<google.maps.DirectionsRenderer | null>(null);
  const { userProfile } = useAuth();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyCwuBL4RWpZuGQ2twJuavcx51S7Q7Xk7ow',
      version: 'weekly',
      libraries: ['places']
    });

    loader.load().then(() => {
      if (!mapRef.current) return;

      // Centrer sur la France par défaut
      const defaultCenter = { lat: 46.227638, lng: 2.213749 };
      
      const mapOptions: google.maps.MapOptions = {
        center: defaultCenter,
        zoom: 6,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }]
          }
        ],
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      };

      mapInstanceRef.current = new google.maps.Map(mapRef.current, mapOptions);
      
      // Initialiser le DirectionsRenderer
      directionsRendererRef.current = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: '#2563eb',
          strokeWeight: 4,
          strokeOpacity: 0.8
        }
      });
      directionsRendererRef.current.setMap(mapInstanceRef.current);

      updateMarkers();
    });
  }, []);

  useEffect(() => {
    if (mapInstanceRef.current) {
      updateMarkers();
    }
  }, [interventions, userProfile?.address]);

  const geocodeAddress = async (address: string): Promise<google.maps.LatLng | null> => {
    const geocoder = new google.maps.Geocoder();
    
    try {
      const formattedAddress = address.toLowerCase().includes('france') ? 
        address : `${address}, France`;

      const result = await geocoder.geocode({ address: formattedAddress });
      
      if (result.results.length === 0) {
        console.warn(`No results found for address: ${address}`);
        return null;
      }

      return result.results[0].geometry.location;
    } catch (error) {
      console.error('Geocoding error:', error);
      return null;
    }
  };

  const calculateAndDisplayRoute = async (waypoints: google.maps.LatLng[]) => {
    if (waypoints.length < 2) return;

    const directionsService = new google.maps.DirectionsService();
    
    try {
      const waypointsFormatted = waypoints.slice(1, -1).map(point => ({
        location: point,
        stopover: true
      }));

      const request: google.maps.DirectionsRequest = {
        origin: waypoints[0],
        destination: waypoints[waypoints.length - 1],
        waypoints: waypointsFormatted,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING
      };

      const result = await directionsService.route(request);
      if (directionsRendererRef.current) {
        directionsRendererRef.current.setDirections(result);
      }
    } catch (error) {
      console.error('Error calculating route:', error);
      setError('Erreur lors du calcul de l\'itinéraire');
    }
  };

  const updateMarkers = async () => {
    // Supprimer les marqueurs existants
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];

    const bounds = new google.maps.LatLngBounds();
    const points: google.maps.LatLng[] = [];
    let validLocationsCount = 0;

    // Ajouter le point de départ (domicile du technicien) si disponible
    if (showRoute && userProfile?.address) {
      const originPoint = await geocodeAddress(userProfile.address);
      if (originPoint) {
        const marker = new google.maps.Marker({
          position: originPoint,
          map: mapInstanceRef.current,
          title: 'Domicile',
          icon: {
            url: '/markers/home.svg',
            scaledSize: new google.maps.Size(30, 30)
          }
        });
        markersRef.current.push(marker);
        bounds.extend(originPoint);
        points.push(originPoint);
        validLocationsCount++;
      }
    }

    // Créer les marqueurs pour les interventions
    for (const intervention of interventions) {
      const position = await geocodeAddress(intervention.location);
      if (!position) continue;

      validLocationsCount++;
      points.push(position);
      
      const marker = new google.maps.Marker({
        position,
        map: mapInstanceRef.current,
        title: intervention.title,
        icon: {
          url: getMarkerIcon(intervention.status),
          scaledSize: new google.maps.Size(30, 30)
        }
      });

      marker.addListener('click', () => {
        if (onMarkerClick) {
          onMarkerClick(intervention);
        }
      });

      const infoWindow = new google.maps.InfoWindow({
        content: `
          <div class="p-2">
            <h3 class="font-medium">${intervention.title}</h3>
            <p class="text-sm text-gray-600">${intervention.client}</p>
            <p class="text-sm text-gray-500">${intervention.date} - ${intervention.time}</p>
          </div>
        `
      });

      marker.addListener('mouseover', () => {
        infoWindow.open(mapInstanceRef.current, marker);
      });

      marker.addListener('mouseout', () => {
        infoWindow.close();
      });

      markersRef.current.push(marker);
      bounds.extend(position);
    }

    // Calculer et afficher l'itinéraire si demandé
    if (showRoute && points.length >= 2) {
      await calculateAndDisplayRoute(points);
    }

    // Ajuster la vue
    if (validLocationsCount > 0) {
      mapInstanceRef.current?.fitBounds(bounds);
      if (validLocationsCount === 1) {
        mapInstanceRef.current?.setZoom(12);
      }
      setError(null);
    } else if (interventions.length > 0) {
      setError('Impossible de localiser les adresses sur la carte');
    }
  };

  const getMarkerIcon = (status: string) => {
    switch (status) {
      case 'pending':
        return '/markers/pending.svg';
      case 'in_progress':
        return '/markers/in-progress.svg';
      case 'completed':
        return '/markers/completed.svg';
      default:
        return '/markers/cancelled.svg';
    }
  };

  return (
    <div className="relative w-full h-full">
      <div ref={mapRef} className="w-full h-full rounded-lg overflow-hidden" />
      {error && (
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2 bg-red-50 border border-red-200 text-red-600 px-4 py-2 rounded-lg flex items-center gap-2">
          <AlertCircle className="w-5 h-5" />
          <span className="text-sm">{error}</span>
        </div>
      )}
    </div>
  );
}
import React from 'react';
import { ClientDashboard } from '../components/client/ClientDashboard';
import { useAuth } from '../contexts/AuthContext';

export function ClientPortal() {
  const { userProfile } = useAuth();

  if (!userProfile) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-200px)]">
        <p className="text-gray-500">Veuillez vous connecter pour accéder au portail client.</p>
      </div>
    );
  }

  return <ClientDashboard />;
}
import { 
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
  User
} from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import type { UserRole } from '../../types';

export async function signIn(email: string, password: string) {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    
    // Récupérer le profil utilisateur depuis Firestore
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (!userDoc.exists()) {
      throw new Error('Profil utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const profile = {
      id: user.uid,
      name: userData.name || user.displayName || '',
      email: user.email || '',
      role: userData.role,
      company: userData.company || null,
      ...userData
    };

    return { user, profile };
  } catch (error: any) {
    console.error('Sign in error:', error);
    throw error;
  }
}

export async function signOut() {
  try {
    await firebaseSignOut(auth);
  } catch (error) {
    console.error('Sign out error:', error);
    throw error;
  }
}

export async function createUser(email: string, password: string, role: UserRole, profileData: any) {
  try {
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    
    // Créer le profil utilisateur dans Firestore
    await setDoc(doc(db, 'users', user.uid), {
      id: user.uid,
      email,
      role,
      company: profileData.company || null,
      ...profileData,
      createdAt: new Date()
    });

    return { user };
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
}

export async function getCurrentUserProfile() {
  try {
    if (!auth.currentUser) return null;
    
    const docRef = doc(db, 'users', auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const userData = docSnap.data();
      return {
        id: docSnap.id,
        name: userData.name || auth.currentUser.displayName || '',
        email: auth.currentUser.email || '',
        role: userData.role,
        company: userData.company || null,
        ...userData
      };
    }
    
    return null;
  } catch (error) {
    console.error('Error getting user profile:', error);
    throw error;
  }
}

export async function updateUserProfile(userId: string, data: {
  name?: string;
  email?: string;
  phone?: string;
  address?: string;
  company?: string;
}) {
  try {
    const userRef = doc(db, 'users', userId);
    
    await updateDoc(userRef, {
      ...data,
      updatedAt: new Date()
    });

    if (data.name && auth.currentUser) {
      await updateProfile(auth.currentUser, {
        displayName: data.name
      });
    }

    const updatedDoc = await getDoc(userRef);
    return updatedDoc.data();
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
}

export async function resetPassword(email: string) {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
}
import React, { createContext, useContext, useState, useEffect } from 'react';
import { Technician, TechnicianStatus } from '../types';
import { getTechnicians, createTechnician, updateTechnician, deleteTechnician } from '../lib/firebase/technicians';

interface TechniciansContextType {
  technicians: Technician[];
  loading: boolean;
  error: string | null;
  filterStatus: TechnicianStatus | 'all';
  setFilterStatus: (status: TechnicianStatus | 'all') => void;
  createNewTechnician: (technician: Omit<Technician, 'id'>) => Promise<string>;
  updateTechnicianData: (id: string, data: Partial<Technician>) => Promise<void>;
  removeTechnician: (id: string) => Promise<void>;
  refreshTechnicians: () => Promise<void>;
}

const TechniciansContext = createContext<TechniciansContextType | null>(null);

export function TechniciansProvider({ children }: { children: React.ReactNode }) {
  const [technicians, setTechnicians] = useState<Technician[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterStatus, setFilterStatus] = useState<TechnicianStatus | 'all'>('all');
  const [refreshKey, setRefreshKey] = useState(0);

  const fetchTechnicians = async () => {
    try {
      console.log('Fetching technicians...', { filterStatus });
      setLoading(true);
      setError(null);
      const data = await getTechnicians(filterStatus === 'all' ? undefined : filterStatus);
      console.log('Fetched technicians:', data);
      setTechnicians(data);
    } catch (err) {
      console.error('Error fetching technicians:', err);
      setError('Erreur lors du chargement des techniciens');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('TechniciansContext effect triggered', { filterStatus, refreshKey });
    fetchTechnicians();
  }, [filterStatus, refreshKey]);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Auto-refresh triggered');
      setRefreshKey(prev => prev + 1);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const createNewTechnician = async (technician: Omit<Technician, 'id'>) => {
    console.log('Creating new technician:', technician);
    const id = await createTechnician(technician);
    console.log('Created technician with ID:', id);
    setRefreshKey(prev => prev + 1);
    return id;
  };

  const updateTechnicianData = async (id: string, data: Partial<Technician>) => {
    console.log('Updating technician:', { id, data });
    await updateTechnician(id, data);
    setRefreshKey(prev => prev + 1);
  };

  const removeTechnician = async (id: string) => {
    console.log('Removing technician:', id);
    await deleteTechnician(id);
    setRefreshKey(prev => prev + 1);
  };

  const value = {
    technicians,
    loading,
    error,
    filterStatus,
    setFilterStatus,
    createNewTechnician,
    updateTechnicianData,
    removeTechnician,
    refreshTechnicians: fetchTechnicians
  };

  return (
    <TechniciansContext.Provider value={value}>
      {children}
    </TechniciansContext.Provider>
  );
}

export function useTechnicians() {
  const context = useContext(TechniciansContext);
  if (!context) {
    throw new Error('useTechnicians must be used within a TechniciansProvider');
  }
  return context;
}
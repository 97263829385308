import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Calculator, 
  Building2, 
  HelpCircle, 
  UserCircle, 
  Users,
  Wrench,
  LogOut,
  Users2,
  ClipboardList,
  FileText,
  ChevronDown,
  ChevronRight,
  Car,
  Euro,
  Settings,
  Briefcase,
  MapPin
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

interface MenuItem {
  id: string;
  icon: React.ElementType;
  text: string;
  path?: string;
  children?: MenuItem[];
}

interface SidebarProps {
  onClose?: () => void;
}

export function Sidebar({ onClose }: SidebarProps) {
  const { userProfile, logout } = useAuth();
  const [openMenus, setOpenMenus] = useState<string[]>([]);
  const role = userProfile?.role || 'client';

  const toggleSubmenu = (id: string) => {
    setOpenMenus(prev => 
      prev.includes(id) 
        ? prev.filter(menuId => menuId !== id)
        : [...prev, id]
    );
  };

  const getMenuItems = (): MenuItem[] => {
    // Menu pour les techniciens
    if (role === 'technician') {
      return [
        { id: 'dashboard', icon: LayoutDashboard, text: 'TABLEAU DE BORD', path: '/dashboard' },
        {
          id: 'interventions',
          icon: ClipboardList,
          text: 'INTERVENTIONS',
          children: [
            { id: 'interventions-list', icon: ClipboardList, text: 'Liste', path: '/interventions' },
            { id: 'sites', icon: MapPin, text: 'Sites', path: '/sites' }
          ]
        },
        { id: 'account', icon: UserCircle, text: 'MON COMPTE', path: '/compte' },
        { id: 'help', icon: HelpCircle, text: 'AIDE', path: '/aide' }
      ];
    }

    // Menu pour les clients
    if (role === 'client') {
      return [
        { id: 'dashboard', icon: LayoutDashboard, text: 'TABLEAU DE BORD', path: '/dashboard' },
        {
          id: 'interventions',
          icon: ClipboardList,
          text: 'INTERVENTIONS',
          children: [
            { id: 'interventions-list', icon: ClipboardList, text: 'Liste', path: '/interventions' },
            { id: 'sites', icon: MapPin, text: 'Sites', path: '/sites' }
          ]
        },
        {
          id: 'company',
          icon: Building2,
          text: 'ENTREPRISE',
          children: [
            { id: 'company-info', icon: Building2, text: 'Informations entreprise', path: '/entreprise' },
            { id: 'accounting', icon: Calculator, text: 'Comptabilité', path: '/comptabilite' }
          ]
        },
        { id: 'account', icon: UserCircle, text: 'MON COMPTE', path: '/compte' },
        { id: 'help', icon: HelpCircle, text: 'AIDE', path: '/aide' }
      ];
    }

    // Menu pour admin/dispatcher
    return [
      { id: 'dashboard', icon: LayoutDashboard, text: 'TABLEAU DE BORD', path: '/dashboard' },
      {
        id: 'interventions',
        icon: ClipboardList,
        text: 'INTERVENTIONS',
        children: [
          { id: 'interventions-list', icon: ClipboardList, text: 'Liste', path: '/interventions' },
          { id: 'sites', icon: MapPin, text: 'Sites', path: '/sites' },
          { id: 'projects', icon: Briefcase, text: 'Projets', path: '/projects' }
        ]
      },
      {
        id: 'clients',
        icon: Users2,
        text: 'CLIENTS',
        children: [
          { id: 'clients-list', icon: Users2, text: 'Liste des clients', path: '/clients' },
          { id: 'contracts', icon: FileText, text: 'Contrats', path: '/contracts' }
        ]
      },
      {
        id: 'technicians',
        icon: Users,
        text: 'TECHNICIENS',
        children: [
          { id: 'technicians-list', icon: Users, text: 'Liste', path: '/technicians' },
          { id: 'skills', icon: Wrench, text: 'Compétences', path: '/skills' }
        ]
      },
      {
        id: 'equipment',
        icon: Wrench,
        text: 'ÉQUIPEMENT',
        children: [
          { id: 'equipment-list', icon: Wrench, text: 'Matériel', path: '/equipment' },
          { id: 'fleet', icon: Car, text: 'Véhicules', path: '/fleet' }
        ]
      },
      {
        id: 'company',
        icon: Building2,
        text: 'ENTREPRISE',
        children: [
          { id: 'company-info', icon: Building2, text: 'Informations entreprise', path: '/entreprise' },
          { id: 'accounting', icon: Calculator, text: 'Comptabilité', path: '/comptabilite' }
        ]
      },
      {
        id: 'settings',
        icon: Settings,
        text: 'PARAMÈTRES',
        children: [
          { id: 'account', icon: UserCircle, text: 'Mon compte', path: '/compte' }
        ]
      },
      { id: 'help', icon: HelpCircle, text: 'AIDE', path: '/aide' }
    ];
  };

  const menuItems = getMenuItems();

  return (
    <aside className="w-64 min-h-screen flex flex-col bg-gradient-to-b from-blue-600 via-blue-700 to-indigo-800 sticky top-0">
      <div className="p-6">
        <h1 className="text-2xl font-bold text-white">DeltAPP</h1>
      </div>

      <nav className="flex-1 px-4 overflow-y-auto scrollbar-thin scrollbar-thumb-white/10 scrollbar-track-transparent space-y-2">
        {menuItems.map((item) => {
          const hasChildren = item.children && item.children.length > 0;
          const isOpen = openMenus.includes(item.id);

          if (hasChildren) {
            return (
              <div key={item.id}>
                <button
                  onClick={() => toggleSubmenu(item.id)}
                  className="w-full flex items-center justify-between p-3 rounded-xl text-white/70 hover:bg-white/10 hover:text-white"
                >
                  <div className="flex items-center space-x-3">
                    <item.icon className="h-5 w-5" />
                    <span className="text-sm font-medium">{item.text}</span>
                  </div>
                  {isOpen ? (
                    <ChevronDown className="h-4 w-4" />
                  ) : (
                    <ChevronRight className="h-4 w-4" />
                  )}
                </button>
                {isOpen && (
                  <div className="ml-4 pl-4 border-l border-white/10 space-y-1">
                    {item.children.map(child => (
                      <NavLink
                        key={child.id}
                        to={child.path!}
                        className={({ isActive }) => `
                          w-full flex items-center space-x-3 p-3 rounded-xl transition-all
                          ${isActive 
                            ? 'bg-white/20 text-white' 
                            : 'text-white/70 hover:bg-white/10 hover:text-white'}
                        `}
                      >
                        <child.icon className="h-4 w-4" />
                        <span className="text-sm">{child.text}</span>
                      </NavLink>
                    ))}
                  </div>
                )}
              </div>
            );
          }

          return (
            <NavLink
              key={item.id}
              to={item.path!}
              className={({ isActive }) => `
                w-full flex items-center space-x-3 p-3 rounded-xl transition-all
                ${isActive 
                  ? 'bg-white/20 text-white' 
                  : 'text-white/70 hover:bg-white/10 hover:text-white'}
              `}
            >
              <item.icon className="h-5 w-5" />
              <span className="text-sm font-medium">{item.text}</span>
            </NavLink>
          );
        })}
      </nav>

      <div className="p-4 mt-auto border-t border-white/10">
        <button
          onClick={logout}
          className="w-full flex items-center space-x-3 text-white/70 hover:text-white p-3 rounded-xl hover:bg-white/10 transition-all"
        >
          <LogOut className="h-5 w-5" />
          <span className="text-sm font-medium">DÉCONNEXION</span>
        </button>
      </div>
    </aside>
  );
}
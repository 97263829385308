import React, { useState, useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import type { Intervention } from '../../types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface RevenueChartProps {
  interventions: Intervention[];
}

type TimeRange = 'week' | 'month' | 'year';

export function RevenueChart({ interventions }: RevenueChartProps) {
  const [timeRange, setTimeRange] = useState<TimeRange>('week');

  const chartData = useMemo(() => {
    const today = new Date();
    let labels: string[] = [];
    let salesData: number[] = [];
    let subcontractorData: number[] = [];
    let marginData: number[] = [];

    const calculateRevenue = (interventions: Intervention[]) => {
      const sales = interventions.reduce((sum, i) => sum + Math.max(0, (i.price || 0) + (i.expenses || 0)), 0);
      const subcontractorCosts = interventions.reduce((sum, i) => sum + Math.max(0, (i.subcontractorPrice || 0) + (i.subcontractorCost || 0)), 0);
      const margin = Math.max(0, sales - subcontractorCosts);
      return { sales, subcontractorCosts, margin };
    };

    if (timeRange === 'week') {
      const days = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
      labels = days;

      // Get start of week (Monday)
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1));

      for (let i = 0; i < 7; i++) {
        const date = new Date(startOfWeek);
        date.setDate(date.getDate() + i);
        const dateStr = date.toISOString().split('T')[0];

        const dayInterventions = interventions.filter(i => i.date === dateStr);
        const { sales, subcontractorCosts, margin } = calculateRevenue(dayInterventions);
        salesData.push(sales);
        subcontractorData.push(subcontractorCosts);
        marginData.push(margin);
      }
    } else if (timeRange === 'month') {
      // Get days in current month
      const year = today.getFullYear();
      const month = today.getMonth();
      const daysInMonth = new Date(year, month + 1, 0).getDate();

      for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(year, month, i);
        const dateStr = date.toISOString().split('T')[0];
        labels.push(i.toString());

        const dayInterventions = interventions.filter(i => i.date === dateStr);
        const { sales, subcontractorCosts, margin } = calculateRevenue(dayInterventions);
        salesData.push(sales);
        subcontractorData.push(subcontractorCosts);
        marginData.push(margin);
      }
    } else if (timeRange === 'year') {
      const months = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'];
      labels = months;

      for (let i = 0; i < 12; i++) {
        const startDate = new Date(today.getFullYear(), i, 1);
        const endDate = new Date(today.getFullYear(), i + 1, 0);

        const monthInterventions = interventions.filter(i => {
          const date = new Date(i.date);
          return date >= startDate && date <= endDate;
        });

        const { sales, subcontractorCosts, margin } = calculateRevenue(monthInterventions);
        salesData.push(sales);
        subcontractorData.push(subcontractorCosts);
        marginData.push(margin);
      }
    }

    return {
      labels,
      datasets: [
        {
          label: 'CA Total',
          data: salesData,
          borderColor: 'rgb(59, 130, 246)',
          backgroundColor: (context: any) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, 'rgba(59, 130, 246, 0.2)');
            gradient.addColorStop(1, 'rgba(59, 130, 246, 0)');
            return gradient;
          },
          tension: 0.6,
          fill: true,
          pointRadius: 4,
          pointHoverRadius: 6,
          pointBackgroundColor: 'rgb(59, 130, 246)',
          borderWidth: 3,
        },
        {
          label: 'Sous-traitants',
          data: subcontractorData,
          borderColor: 'rgb(239, 68, 68)',
          backgroundColor: (context: any) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, 'rgba(239, 68, 68, 0.2)');
            gradient.addColorStop(1, 'rgba(239, 68, 68, 0)');
            return gradient;
          },
          tension: 0.6,
          fill: true,
          pointRadius: 4,
          pointHoverRadius: 6,
          pointBackgroundColor: 'rgb(239, 68, 68)',
          borderWidth: 3,
        },
        {
          label: 'Marge',
          data: marginData,
          borderColor: 'rgb(34, 197, 94)',
          backgroundColor: (context: any) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, 'rgba(34, 197, 94, 0.2)');
            gradient.addColorStop(1, 'rgba(34, 197, 94, 0)');
            return gradient;
          },
          tension: 0.6,
          fill: true,
          pointRadius: 4,
          pointHoverRadius: 6,
          pointBackgroundColor: 'rgb(34, 197, 94)',
          borderWidth: 3,
        }
      ],
    };
  }, [interventions, timeRange]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          usePointStyle: true,
          padding: 20,
          font: {
            family: "'Inter', sans-serif",
            size: 12
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        titleColor: '#1f2937',
        bodyColor: '#4b5563',
        borderColor: '#e5e7eb',
        borderWidth: 1,
        padding: 12,
        bodyFont: {
          family: "'Inter', sans-serif",
          size: 12
        },
        titleFont: {
          family: "'Inter', sans-serif",
          size: 14,
          weight: 'bold'
        },
        callbacks: {
          label: function(context: any) {
            return `${context.dataset.label}: ${context.parsed.y.toLocaleString()}€`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            family: "'Inter', sans-serif",
            size: 12
          }
        }
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            family: "'Inter', sans-serif",
            size: 12
          },
          callback: (value: number) => `${value.toLocaleString()}€`
        },
        beginAtZero: true
      }
    }
  };

  return (
    <div style={{ height: '300px' }}>
      <div className="flex justify-end gap-2 mb-4">
        <button
          onClick={() => setTimeRange('week')}
          className={`px-3 py-1 text-sm rounded-lg ${
            timeRange === 'week' 
              ? 'bg-blue-100 text-blue-600' 
              : 'text-gray-600 hover:bg-gray-100'
          }`}
        >
          Semaine
        </button>
        <button
          onClick={() => setTimeRange('month')}
          className={`px-3 py-1 text-sm rounded-lg ${
            timeRange === 'month' 
              ? 'bg-blue-100 text-blue-600' 
              : 'text-gray-600 hover:bg-gray-100'
          }`}
        >
          Mois
        </button>
        <button
          onClick={() => setTimeRange('year')}
          className={`px-3 py-1 text-sm rounded-lg ${
            timeRange === 'year' 
              ? 'bg-blue-100 text-blue-600' 
              : 'text-gray-600 hover:bg-gray-100'
          }`}
        >
          Année
        </button>
      </div>
      <div className="h-[calc(100%-40px)]">
        <Line options={options} data={chartData} />
      </div>
    </div>
  );
}
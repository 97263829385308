import React, { useState } from 'react';
import { useInterventions } from '../../contexts/InterventionsContext';
import { useClients } from '../../contexts/ClientsContext';
import { useAccounting } from '../../contexts/AccountingContext';
import { Calendar, Euro, AlertCircle, Download, Clock } from 'lucide-react';

const getStatusColor = (status: string) => {
  switch (status) {
    case 'draft':
      return 'bg-gray-100 text-gray-800';
    case 'sent':
      return 'bg-blue-100 text-blue-800';
    case 'paid':
      return 'bg-green-100 text-green-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

const getStatusText = (status: string) => {
  switch (status) {
    case 'draft':
      return 'Brouillon';
    case 'sent':
      return 'Envoyée';
    case 'paid':
      return 'Payée';
    default:
      return status;
  }
};

export function MonthlyInvoices() {
  const { interventions } = useInterventions();
  const { clients } = useClients();
  const { selectedMonth, selectedYear, setSelectedMonth, setSelectedYear, invoices } = useAccounting();
  const [error, setError] = useState<string | null>(null);

  const months = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];

  // Calculer le total des ventes pour le mois sélectionné
  const calculateMonthlyTotal = () => {
    return interventions
      .filter(intervention => {
        const date = new Date(intervention.date);
        return date.getMonth() === selectedMonth && 
               date.getFullYear() === selectedYear &&
               intervention.status === 'completed';
      })
      .reduce((total, intervention) => {
        // Prix de vente HT
        const price = intervention.price || 0;
        // Frais vendus HT
        const expenses = intervention.expenses || 0;
        
        // Total HT = Prix de vente + Frais vendus
        return total + price + expenses;
      }, 0);
  };

  // Calculer le total des coûts sous-traitants pour le mois sélectionné
  const calculateSubcontractorTotal = () => {
    return interventions
      .filter(intervention => {
        const date = new Date(intervention.date);
        return date.getMonth() === selectedMonth && 
               date.getFullYear() === selectedYear &&
               intervention.status === 'completed';
      })
      .reduce((total, intervention) => {
        // Prix sous-traitant HT
        const price = intervention.subcontractorPrice || 0;
        // Frais sous-traitant HT
        const cost = intervention.subcontractorCost || 0;
        
        // Total HT = Prix sous-traitant + Frais sous-traitant
        return total + price + cost;
      }, 0);
  };

  // Calculer la marge pour le mois sélectionné
  const calculateMargin = () => {
    const totalSales = calculateMonthlyTotal();
    const totalCosts = calculateSubcontractorTotal();
    return totalSales - totalCosts;
  };

  const monthlyTotal = calculateMonthlyTotal();
  const subcontractorTotal = calculateSubcontractorTotal();
  const margin = calculateMargin();

  return (
    <div className="space-y-6">
      {/* Sélecteur de période */}
      <div className="flex items-center gap-4 bg-white p-4 rounded-xl shadow-sm">
        <select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
          className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          {months.map((month, index) => (
            <option key={index} value={index}>
              {month}
            </option>
          ))}
        </select>
        <select
          value={selectedYear}
          onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          {Array.from({ length: 5 }, (_, i) => {
            const year = new Date().getFullYear() - 2 + i;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
      </div>

      {/* Totaux */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="flex items-center gap-3 mb-2">
            <div className="w-10 h-10 bg-blue-100 rounded-lg flex items-center justify-center">
              <Euro className="w-5 h-5 text-blue-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Chiffre d'affaires</p>
              <p className="text-2xl font-bold text-gray-900">{monthlyTotal.toLocaleString()}€</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="flex items-center gap-3 mb-2">
            <div className="w-10 h-10 bg-red-100 rounded-lg flex items-center justify-center">
              <Euro className="w-5 h-5 text-red-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Coûts sous-traitants</p>
              <p className="text-2xl font-bold text-gray-900">{subcontractorTotal.toLocaleString()}€</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="flex items-center gap-3 mb-2">
            <div className="w-10 h-10 bg-green-100 rounded-lg flex items-center justify-center">
              <Euro className="w-5 h-5 text-green-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Marge</p>
              <p className="text-2xl font-bold text-gray-900">{margin.toLocaleString()}€</p>
            </div>
          </div>
        </div>
      </div>

      {/* Liste des factures */}
      <div className="bg-white rounded-xl shadow-sm">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold text-gray-900">Factures du mois</h2>
            <button
              className="flex items-center gap-2 px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
            >
              <Download className="w-4 h-4" />
              Exporter
            </button>
          </div>
        </div>
        <div className="p-6">
          {invoices.length === 0 ? (
            <p className="text-center text-gray-500">Aucune facture pour cette période</p>
          ) : (
            <div className="space-y-4">
              {invoices.map((invoice) => {
                const client = clients.find(c => c.id === invoice.clientId);
                return (
                  <div key={invoice.id} className="border border-gray-200 rounded-lg p-4">
                    <div className="flex items-center justify-between mb-4">
                      <div>
                        <h3 className="font-medium text-gray-900">{client?.name || 'Client inconnu'}</h3>
                        <p className="text-sm text-gray-500">Facture #{invoice.id}</p>
                      </div>
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(invoice.status)}`}>
                        {getStatusText(invoice.status)}
                      </span>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <p className="text-sm text-gray-500">Montant</p>
                        <p className="font-medium">{invoice.totalAmount.toLocaleString()}€</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Interventions</p>
                        <p className="font-medium">{invoice.interventionsCount}</p>
                      </div>
                    </div>
                    {invoice.depositDate && (
                      <div className="mt-4 flex items-center gap-2 text-sm text-gray-600">
                        <Clock className="w-4 h-4" />
                        <span>Date d'encaissement : {new Date(invoice.depositDate).toLocaleDateString()}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
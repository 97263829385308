import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDyB-hNCfmOl5C8IeoXfXFPFYZXHMbfKv8",
  authDomain: "deltinfoapp.firebaseapp.com",
  projectId: "deltinfoapp",
  storageBucket: "deltinfoapp.firebasestorage.app",
  messagingSenderId: "543145029892",
  appId: "1:543145029892:web:2b3ea389862e471565e051",
  measurementId: "G-8W9PKQV0CG"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);
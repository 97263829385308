import React from 'react';
import { NavLink } from 'react-router-dom';
import { 
  LayoutDashboard, 
  ClipboardList, 
  Users2, 
  Wrench,
  Calculator,
  Building2,
  UserCircle,
  HelpCircle
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

export function MobileNavigation() {
  const { userProfile } = useAuth();
  const role = userProfile?.role || 'client';

  const getMenuItems = () => {
    // Menu pour les techniciens
    if (role === 'technician') {
      return [
        { id: 'dashboard', icon: LayoutDashboard, label: 'Accueil', path: '/dashboard' },
        { id: 'interventions', icon: ClipboardList, label: 'Interventions', path: '/interventions' },
        { id: 'account', icon: UserCircle, label: 'Compte', path: '/compte' },
        { id: 'help', icon: HelpCircle, label: 'Aide', path: '/aide' }
      ];
    }

    // Menu pour les clients
    if (role === 'client') {
      return [
        { id: 'dashboard', icon: LayoutDashboard, label: 'Accueil', path: '/dashboard' },
        { id: 'account', icon: UserCircle, label: 'Compte', path: '/compte' },
        { id: 'help', icon: HelpCircle, label: 'Aide', path: '/aide' }
      ];
    }

    // Menu pour admin/dispatcher
    return [
      { id: 'dashboard', icon: LayoutDashboard, label: 'Accueil', path: '/dashboard' },
      { id: 'interventions', icon: ClipboardList, label: 'Interventions', path: '/interventions' },
      { id: 'clients', icon: Users2, label: 'Clients', path: '/clients' },
      { id: 'technicians', icon: Users2, label: 'Techniciens', path: '/technicians' }
    ];
  };

  const menuItems = getMenuItems();

  return (
    <nav className="fixed bottom-0 left-0 right-0 z-50">
      <div className="bg-gradient-to-r from-blue-600 via-blue-700 to-indigo-800 px-4 py-2 backdrop-blur-lg">
        <div className="flex justify-around items-center max-w-lg mx-auto">
          {menuItems.map((item) => (
            <NavLink
              key={item.id}
              to={item.path}
              className={({ isActive }) => `
                flex flex-col items-center gap-1 p-2 rounded-xl transition-colors
                ${isActive 
                  ? 'text-white bg-white/20' 
                  : 'text-white/70 hover:text-white hover:bg-white/10'
                }
              `}
            >
              <item.icon className="w-6 h-6" />
              <span className="text-xs font-medium">{item.label}</span>
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
}
import React, { useState } from 'react';
import { Clock, Play, CheckCircle, Plus, MapPin, Calendar, FileText, Building2, Package, Upload, X } from 'lucide-react';
import { useInterventions } from '../../contexts/InterventionsContext';
import { useAuth } from '../../contexts/AuthContext';
import { DashboardWidget } from '../dashboard/DashboardWidget';
import { AddressInput } from '../interventions/AddressInput';
import { getSites } from '../../lib/firebase/sites';
import { getContracts } from '../../lib/firebase/contracts';
import type { ValidatedAddress, Site, Contract } from '../../types';

export function ClientDashboard() {
  const { interventions, createNewIntervention } = useInterventions();
  const { userProfile } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validatedAddress, setValidatedAddress] = useState<ValidatedAddress | null>(null);
  const [sites, setSites] = useState<Site[]>([]);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [trackingNumbers, setTrackingNumbers] = useState<string[]>([]);

  // Charger les sites et contrats au chargement
  React.useEffect(() => {
    const loadData = async () => {
      if (userProfile?.company) {
        const [sitesData, contractsData] = await Promise.all([
          getSites(userProfile.company),
          getContracts()
        ]);
        setSites(sitesData);
        setContracts(contractsData.filter(c => c.clientId === userProfile.company));
      }
    };
    loadData();
  }, [userProfile]);

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  // Filter today's interventions for this client
  const clientInterventions = interventions.filter(i => 
    i.clientId === userProfile?.company
  );

  const todayInterventions = clientInterventions.filter(i => i.date === today);

  // Calculate stats
  const stats = {
    pending: clientInterventions.filter(i => i.status === 'pending').length,
    inProgress: clientInterventions.filter(i => i.status === 'in_progress').length,
    completed: clientInterventions.filter(i => i.status === 'completed').length,
    total: clientInterventions.length || 1 // Prevent division by zero
  };

  // État du formulaire rapide
  const [quickForm, setQuickForm] = useState({
    title: '',
    siteId: '',
    contractId: '',
    date: '',
    time: '',
    description: '',
    location: ''
  });

  const handleQuickSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userProfile?.company) {
      setError('Erreur d\'identification client');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Si un site est sélectionné, utiliser son adresse
      const selectedSite = sites.find(s => s.id === quickForm.siteId);
      const location = selectedSite ? selectedSite.address : quickForm.location;
      const coordinates = selectedSite ? selectedSite.coordinates : validatedAddress?.lat ? {
        lat: validatedAddress.lat,
        lng: validatedAddress.lng
      } : undefined;

      await createNewIntervention({
        title: quickForm.title,
        clientId: userProfile.company,
        client: userProfile.name || '',
        siteId: quickForm.siteId,
        contractId: quickForm.contractId,
        location: location,
        coordinates,
        date: quickForm.date,
        time: quickForm.time || undefined,
        description: quickForm.description,
        status: 'pending',
        isTimeStrict: false,
        price: 0,
        expenses: 0,
        subcontractorPrice: 0,
        subcontractorCost: 0,
        trackingNumbers
      });

      // Reset form
      setQuickForm({
        title: '',
        siteId: '',
        contractId: '',
        date: '',
        time: '',
        description: '',
        location: ''
      });
      setValidatedAddress(null);
      setTrackingNumbers([]);
      setFiles([]);
    } catch (err) {
      setError('Erreur lors de la création de l\'intervention');
      console.error('Error creating intervention:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setQuickForm(prev => ({
      ...prev,
      [name]: value
    }));

    // Si un site est sélectionné, mettre à jour l'adresse
    if (name === 'siteId') {
      const site = sites.find(s => s.id === value);
      if (site) {
        setQuickForm(prev => ({
          ...prev,
          location: site.address
        }));
      }
    }
  };

  const addTrackingNumber = () => {
    const number = prompt('Entrez le numéro de suivi:');
    if (number?.trim()) {
      setTrackingNumbers(prev => [...prev, number.trim()]);
    }
  };

  const removeTrackingNumber = (index: number) => {
    setTrackingNumbers(prev => prev.filter((_, i) => i !== index));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      setFiles(prev => [...prev, ...newFiles]);
    }
  };

  const removeFile = (index: number) => {
    setFiles(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="space-y-6">
      {/* Widgets */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <DashboardWidget
          title="EN ATTENTE"
          count={stats.pending}
          total={stats.total}
          icon={Clock}
          iconColor="text-orange-600"
          progressColor="stroke-orange-500"
        />
        <DashboardWidget
          title="EN COURS"
          count={stats.inProgress}
          total={stats.total}
          icon={Play}
          iconColor="text-blue-600"
          progressColor="stroke-blue-500"
        />
        <DashboardWidget
          title="TERMINÉES"
          count={stats.completed}
          total={stats.total}
          icon={CheckCircle}
          iconColor="text-emerald-600"
          progressColor="stroke-emerald-500"
        />
      </div>

      {/* Création rapide */}
      <div className="bg-white rounded-xl shadow-sm">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
            <Plus className="w-5 h-5 text-blue-600" />
            Création rapide d'intervention
          </h2>
        </div>

        <form onSubmit={handleQuickSubmit} className="p-6 space-y-6">
          {error && (
            <div className="p-3 bg-red-50 border border-red-200 rounded-lg text-red-600 text-sm">
              {error}
            </div>
          )}

          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
              Titre
            </label>
            <div className="relative">
              <FileText className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
              <input
                type="text"
                id="title"
                name="title"
                required
                value={quickForm.title}
                onChange={handleChange}
                className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Ex: Maintenance serveur"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="siteId" className="block text-sm font-medium text-gray-700 mb-1">
                Site
              </label>
              <select
                id="siteId"
                name="siteId"
                value={quickForm.siteId}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Sélectionner un site</option>
                {sites.map(site => (
                  <option key={site.id} value={site.id}>
                    {site.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="contractId" className="block text-sm font-medium text-gray-700 mb-1">
                Contrat
              </label>
              <select
                id="contractId"
                name="contractId"
                value={quickForm.contractId}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Sélectionner un contrat</option>
                {contracts.map(contract => (
                  <option key={contract.id} value={contract.id}>
                    {contract.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {!quickForm.siteId && (
            <div>
              <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
                Lieu d'intervention
              </label>
              <AddressInput
                value={quickForm.location}
                onChange={(address, validated) => {
                  setQuickForm(prev => ({ ...prev, location: address }));
                  if (validated) {
                    setValidatedAddress(validated);
                  }
                }}
                required={!quickForm.siteId}
                error={error}
              />
            </div>
          )}

          <div className="grid grid-cols-2 gap-6">
            <div>
              <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
                Date
              </label>
              <div className="relative">
                <Calendar className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <input
                  type="date"
                  id="date"
                  name="date"
                  required
                  value={quickForm.date}
                  onChange={handleChange}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div>
              <label htmlFor="time" className="block text-sm font-medium text-gray-700 mb-1">
                Heure (optionnel)
              </label>
              <div className="relative">
                <Clock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                <input
                  type="time"
                  id="time"
                  name="time"
                  value={quickForm.time}
                  onChange={handleChange}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              rows={3}
              required
              value={quickForm.description}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Description de l'intervention..."
            />
          </div>

          {/* Numéros de suivi */}
          <div>
            <div className="flex items-center justify-between mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Numéros de suivi (optionnel)
              </label>
              <button
                type="button"
                onClick={addTrackingNumber}
                className="text-sm text-blue-600 hover:text-blue-700 flex items-center gap-1"
              >
                <Package className="w-4 h-4" />
                Ajouter un numéro
              </button>
            </div>
            {trackingNumbers.length > 0 && (
              <div className="space-y-2">
                {trackingNumbers.map((number, index) => (
                  <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded-lg">
                    <div className="flex items-center gap-2">
                      <Package className="w-4 h-4 text-gray-400" />
                      <span className="text-sm text-gray-600">{number}</span>
                    </div>
                    <button
                      type="button"
                      onClick={() => removeTrackingNumber(index)}
                      className="text-sm text-red-600 hover:text-red-700"
                    >
                      Supprimer
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Pièces jointes */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Pièces jointes (optionnel)
            </label>
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  className="hidden"
                  id="files"
                />
                <label
                  htmlFor="files"
                  className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer flex items-center gap-2"
                >
                  <Upload className="w-4 h-4" />
                  Ajouter des fichiers
                </label>
              </div>
              {files.length > 0 && (
                <div className="space-y-2">
                  {files.map((file, index) => (
                    <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded-lg">
                      <span className="text-sm text-gray-600">{file.name}</span>
                      <button
                        type="button"
                        onClick={() => removeFile(index)}
                        className="text-red-600 hover:text-red-700"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
            >
              {loading ? (
                <>
                  <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  Création...
                </>
              ) : (
                <>
                  <Plus className="w-4 h-4" />
                  Créer l'intervention
                </>
              )}
            </button>
          </div>
        </form>
      </div>

      {/* Liste des interventions du jour */}
      <div className="bg-white rounded-xl shadow-sm">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900">Interventions du jour</h2>
        </div>
        <div className="p-6">
          {todayInterventions.length === 0 ? (
            <p className="text-center text-gray-500">Aucune intervention prévue aujourd'hui</p>
          ) : (
            <div className="space-y-4">
              {todayInterventions.map((intervention) => (
                <div key={intervention.id} className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex items-start justify-between">
                    <div>
                      <h3 className="font-medium text-gray-900">{intervention.title}</h3>
                      <div className="mt-2 space-y-1">
                        <div className="flex items-center gap-2 text-sm text-gray-600">
                          <MapPin className="w-4 h-4" />
                          {intervention.location}
                        </div>
                        <div className="flex items-center gap-2 text-sm text-gray-600">
                          <Clock className="w-4 h-4" />
                          {intervention.time || 'Heure non définie'}
                        </div>
                      </div>
                    </div>
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                      intervention.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                      intervention.status === 'in_progress' ? 'bg-blue-100 text-blue-800' :
                      'bg-green-100 text-green-800'
                    }`}>
                      {intervention.status === 'pending' ? 'En attente' :
                       intervention.status === 'in_progress' ? 'En cours' :
                       'Terminée'}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
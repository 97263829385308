import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { AlertCircle } from 'lucide-react';
import type { Technician } from '../../types';

interface TechniciansMapProps {
  technicians: Technician[];
}

export function TechniciansMap({ technicians }: TechniciansMapProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyCwuBL4RWpZuGQ2twJuavcx51S7Q7Xk7ow',
      version: 'weekly',
      libraries: ['places']
    });

    const loadMap = async () => {
      try {
        await loader.load();
        if (!mapRef.current) return;

        const geocoder = new google.maps.Geocoder();
        const map = new google.maps.Map(mapRef.current, {
          center: { lat: 46.227638, lng: 2.213749 }, // Centre de la France
          zoom: 6,
          streetViewControl: false, // Désactiver Street View
          mapTypeControl: false, // Désactiver le contrôle de type de carte
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }]
            }
          ]
        });

        const bounds = new google.maps.LatLngBounds();
        let validLocationsCount = 0;

        // Créer les marqueurs pour chaque technicien
        for (const technician of technicians) {
          // Skip if no address
          if (!technician.address?.trim()) {
            console.warn(`No address provided for technician: ${technician.name}`);
            continue;
          }

          try {
            // Ensure address includes France for better geocoding results
            const formattedAddress = technician.address.toLowerCase().includes('france') 
              ? technician.address 
              : `${technician.address}, France`;

            const result = await geocoder.geocode({ address: formattedAddress });

            if (!result.results?.[0]?.geometry?.location) {
              console.warn(`Invalid geocoding result for technician: ${technician.name}`);
              continue;
            }

            const position = result.results[0].geometry.location;
            validLocationsCount++;
            bounds.extend(position);

            // Créer le marqueur avec la couleur correspondante au type de technicien
            const marker = new google.maps.Marker({
              position,
              map,
              title: technician.name,
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: technician.role === 'technician' ? '#3B82F6' : '#EF4444',
                fillOpacity: 1,
                strokeWeight: 1,
                strokeColor: '#FFFFFF',
                scale: 10
              }
            });

            // Ajouter une infowindow avec plus d'informations
            const infoWindow = new google.maps.InfoWindow({
              content: `
                <div class="p-3">
                  <h3 class="font-medium text-gray-900">${technician.name}</h3>
                  <p class="text-sm text-gray-600 mt-1">
                    ${technician.role === 'technician' ? 'Salarié' : 'Sous-traitant'}
                  </p>
                  ${technician.departments.length > 0 ? `
                    <p class="text-sm text-gray-500 mt-1">
                      Départements: ${technician.departments.join(', ')}
                    </p>
                  ` : ''}
                  <p class="text-sm text-gray-500 mt-1">${technician.address}</p>
                </div>
              `
            });

            marker.addListener('click', () => {
              // Fermer toutes les autres infowindows avant d'ouvrir celle-ci
              google.maps.event.trigger(map, 'closeAllInfoWindows');
              infoWindow.open(map, marker);
            });

            // Ajouter un événement personnalisé pour fermer les infowindows
            google.maps.event.addListener(map, 'closeAllInfoWindows', () => {
              infoWindow.close();
            });

          } catch (err) {
            console.warn(`Error geocoding address for technician ${technician.name}:`, err);
          }
        }

        // Ajuster la vue
        if (validLocationsCount > 0) {
          map.fitBounds(bounds);
          if (validLocationsCount === 1) {
            map.setZoom(12);
          }
          setError(null);
        } else {
          setError('Aucune adresse valide trouvée pour les techniciens');
        }

        // Ajouter un listener pour fermer les infowindows au clic sur la carte
        map.addListener('click', () => {
          google.maps.event.trigger(map, 'closeAllInfoWindows');
        });

      } catch (err) {
        console.error('Error loading map:', err);
        setError('Erreur lors du chargement de la carte');
      }
    };

    loadMap();
  }, [technicians]);

  return (
    <div className="relative w-full h-[400px] bg-gray-100 rounded-xl overflow-hidden">
      <div ref={mapRef} className="w-full h-full" />
      {error && (
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2 bg-red-50 border border-red-200 text-red-600 px-4 py-2 rounded-lg flex items-center gap-2">
          <AlertCircle className="w-5 h-5" />
          <span className="text-sm">{error}</span>
        </div>
      )}
      <div className="absolute bottom-4 left-4 bg-white p-4 rounded-lg shadow-md">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-blue-500"></div>
            <span className="text-sm">Salariés</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-red-500"></div>
            <span className="text-sm">Sous-traitants</span>
          </div>
        </div>
      </div>
    </div>
  );
}
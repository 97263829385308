import React, { createContext, useContext, useState, useEffect } from 'react';
import { Equipment, EquipmentStatus } from '../types';
import { getEquipment, createEquipment, updateEquipment, deleteEquipment } from '../lib/firebase/equipment';

interface EquipmentContextType {
  equipment: Equipment[];
  loading: boolean;
  error: string | null;
  filterStatus: EquipmentStatus | 'all';
  setFilterStatus: (status: EquipmentStatus | 'all') => void;
  createNewEquipment: (equipment: Omit<Equipment, 'id'>) => Promise<string>;
  updateEquipmentData: (id: string, data: Partial<Equipment>) => Promise<void>;
  removeEquipment: (id: string) => Promise<void>;
  refreshEquipment: () => Promise<void>;
}

const EquipmentContext = createContext<EquipmentContextType | null>(null);

export function EquipmentProvider({ children }: { children: React.ReactNode }) {
  const [equipment, setEquipment] = useState<Equipment[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterStatus, setFilterStatus] = useState<EquipmentStatus | 'all'>('all');

  const fetchEquipment = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getEquipment(filterStatus === 'all' ? undefined : filterStatus);
      setEquipment(data);
    } catch (err) {
      setError('Erreur lors du chargement des équipements');
      console.error('Error fetching equipment:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEquipment();
  }, [filterStatus]);

  const createNewEquipment = async (equipment: Omit<Equipment, 'id'>) => {
    const id = await createEquipment(equipment);
    await fetchEquipment();
    return id;
  };

  const updateEquipmentData = async (id: string, data: Partial<Equipment>) => {
    await updateEquipment(id, data);
    await fetchEquipment();
  };

  const removeEquipment = async (id: string) => {
    await deleteEquipment(id);
    await fetchEquipment();
  };

  const value = {
    equipment,
    loading,
    error,
    filterStatus,
    setFilterStatus,
    createNewEquipment,
    updateEquipmentData,
    removeEquipment,
    refreshEquipment: fetchEquipment
  };

  return (
    <EquipmentContext.Provider value={value}>
      {children}
    </EquipmentContext.Provider>
  );
}

export function useEquipment() {
  const context = useContext(EquipmentContext);
  if (!context) {
    throw new Error('useEquipment must be used within an EquipmentProvider');
  }
  return context;
}
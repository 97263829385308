import React from 'react';
import { Calculator } from 'lucide-react';
import { PageHeader } from '../components/layout/PageHeader';
import { MonthlyInvoices } from '../components/accounting/MonthlyInvoices';

export function Accounting() {
  return (
    <div className="space-y-6">
      <PageHeader
        title="Comptabilité"
        subtitle="Gérez vos factures et votre comptabilité"
        icon={Calculator}
        gradient="emerald"
      />

      <MonthlyInvoices />
    </div>
  );
}
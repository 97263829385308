import React from 'react';

interface CircularProgressProps {
  value: number;
  color: string;
  size?: number;
  strokeWidth?: number;
}

export function CircularProgress({ 
  value, 
  color, 
  size = 120, 
  strokeWidth = 8 
}: CircularProgressProps) {
  // Ensure value is between 0 and 100
  const normalizedValue = Math.min(Math.max(value || 0, 0), 100);
  
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const progress = normalizedValue / 100;
  const dashoffset = circumference * (1 - progress);

  return (
    <div className="relative" style={{ width: size, height: size }}>
      {/* Background circle */}
      <svg className="absolute transform -rotate-90" width={size} height={size}>
        <circle
          className="text-gray-200"
          strokeWidth={strokeWidth}
          stroke="currentColor"
          fill="none"
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        {/* Progress circle */}
        <circle
          className={color + " transition-all duration-500 ease-in-out"}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={dashoffset}
          strokeLinecap="round"
          stroke="currentColor"
          fill="none"
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
      </svg>
      {/* Center content */}
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-2xl font-bold">{Math.round(normalizedValue)}%</span>
      </div>
    </div>
  );
}
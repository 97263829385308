import { collection, addDoc, getDocs, query, orderBy, updateDoc, doc, deleteDoc, where, Timestamp, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from 'firebase/auth';
import { auth, db } from '../firebase';
import type { Client } from '../../types';

const COLLECTION = 'clients';

export async function getClients() {
  try {
    // Supprimer le filtre de status pour récupérer tous les clients
    const q = query(
      collection(db, COLLECTION),
      orderBy('name')
    );
    
    const querySnapshot = await getDocs(q);
    const clients = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Client[];

    console.log('Fetched clients:', clients); // Pour le débogage
    return clients;
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw error;
  }
}

export async function createClient(clientData: Omit<Client, 'id'>, userData?: { email: string; password: string }) {
  try {
    let userId = null;

    // Si des données utilisateur sont fournies, vérifier d'abord si l'email existe
    if (userData) {
      // Vérifier si l'email existe déjà
      const signInMethods = await fetchSignInMethodsForEmail(auth, userData.email);
      if (signInMethods.length > 0) {
        throw new Error('Cette adresse email est déjà utilisée. Veuillez en choisir une autre.');
      }

      // Créer le compte utilisateur
      const { user } = await createUserWithEmailAndPassword(
        auth,
        userData.email,
        userData.password
      );
      userId = user.uid;

      // Créer le profil utilisateur
      await setDoc(doc(db, 'users', user.uid), {
        id: user.uid,
        email: userData.email,
        name: clientData.contactName || clientData.name,
        company: null, // Sera mis à jour avec l'ID du client
        role: 'client',
        phone: clientData.phone,
        createdAt: Timestamp.now()
      });
    }

    // Créer le client
    const docRef = await addDoc(collection(db, COLLECTION), {
      ...clientData,
      userId: userId,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    });

    // Si un compte utilisateur a été créé, mettre à jour son profil avec l'ID du client
    if (userId) {
      await updateDoc(doc(db, 'users', userId), {
        company: docRef.id
      });

      // Mettre à jour le document client avec l'ID utilisateur
      await updateDoc(docRef, {
        userId: userId
      });
    }

    return docRef.id;
  } catch (error: any) {
    console.error('Error creating client:', error);
    
    // Si l'erreur est liée à l'email, retourner un message plus convivial
    if (error.code === 'auth/email-already-in-use') {
      throw new Error('Cette adresse email est déjà utilisée. Veuillez en choisir une autre.');
    }
    
    throw error;
  }
}

export async function updateClient(id: string, data: Partial<Client>) {
  try {
    const docRef = doc(db, COLLECTION, id);
    await updateDoc(docRef, {
      ...data,
      updatedAt: Timestamp.now()
    });
  } catch (error) {
    console.error('Error updating client:', error);
    throw error;
  }
}

export async function deleteClient(id: string) {
  try {
    await deleteDoc(doc(db, COLLECTION, id));
  } catch (error) {
    console.error('Error deleting client:', error);
    throw error;
  }
}
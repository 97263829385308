import React, { useState } from 'react';
import { LayoutDashboard, Clock, AlertCircle, CheckCircle, UserPlus } from 'lucide-react';
import { useInterventions } from '../contexts/InterventionsContext';
import { useAuth } from '../contexts/AuthContext';
import { DashboardWidget } from '../components/dashboard/DashboardWidget';
import { PageHeader } from '../components/layout/PageHeader';
import { InterventionsMap } from '../components/maps/InterventionsMap';
import { RevenueChart } from '../components/dashboard/RevenueChart';
import { OverdueInterventionsModal } from '../components/interventions/OverdueInterventionsModal';
import { InterventionDetailsModal } from '../components/interventions/InterventionDetailsModal';
import type { Intervention } from '../types';

export function Dashboard() {
  const { interventions } = useInterventions();
  const { userProfile } = useAuth();
  const [showOverdueModal, setShowOverdueModal] = useState(false);
  const [selectedIntervention, setSelectedIntervention] = useState<Intervention | null>(null);

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  // Filter interventions based on user role
  const filteredInterventions = (() => {
    // For admin and dispatcher, show all interventions
    if (userProfile?.role === 'admin' || userProfile?.role === 'dispatcher') {
      return interventions;
    }
    // For technicians, only show their assigned interventions
    if (userProfile?.role === 'technician') {
      return interventions.filter(i => i.mainTechnicianId === userProfile.technicianId);
    }
    // For clients, show their interventions
    if (userProfile?.role === 'client') {
      return interventions.filter(i => i.clientId === userProfile.company);
    }
    return [];
  })();

  // Get today's interventions
  const todayInterventions = filteredInterventions.filter(i => i.date === today);

  // Get overdue interventions (from yesterday or before, not completed)
  const overdueInterventions = filteredInterventions.filter(i => {
    const interventionDate = new Date(i.date);
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    return interventionDate < todayDate && i.status !== 'completed';
  });

  // Calculate stats for today's interventions
  const stats = {
    notStarted: todayInterventions.filter(i => i.status === 'pending').length,
    inProgress: todayInterventions.filter(i => i.status === 'in_progress').length,
    completed: todayInterventions.filter(i => i.status === 'completed').length,
    nonDispatched: todayInterventions.filter(i => !i.mainTechnicianId).length
  };

  // Calculate total for percentage
  const total = Math.max(todayInterventions.length, 1); // Prevent division by zero

  return (
    <div className="space-y-6">
      <PageHeader
        title="Tableau de bord"
        subtitle={`Bienvenue, ${userProfile?.name}`}
        icon={LayoutDashboard}
        gradient="blue"
        actions={
          <button
            onClick={() => setShowOverdueModal(true)}
            className="bg-white/90 backdrop-blur-sm text-red-600 px-4 py-2 rounded-lg hover:bg-white transition-colors flex items-center gap-2 shadow-sm"
          >
            <AlertCircle className="w-4 h-4" />
            Interventions non clôturées ({overdueInterventions.length})
          </button>
        }
      />

      {/* Widgets */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <DashboardWidget
          title="NON COMMENCÉES"
          count={stats.notStarted}
          total={total}
          icon={Clock}
          iconColor="text-orange-600"
          progressColor="stroke-orange-500"
        />
        <DashboardWidget
          title="EN COURS"
          count={stats.inProgress}
          total={total}
          icon={AlertCircle}
          iconColor="text-blue-600"
          progressColor="stroke-blue-500"
        />
        <DashboardWidget
          title="TERMINÉES"
          count={stats.completed}
          total={total}
          icon={CheckCircle}
          iconColor="text-emerald-600"
          progressColor="stroke-emerald-500"
        />
        <DashboardWidget
          title="NON ASSIGNÉES"
          count={stats.nonDispatched}
          total={total}
          icon={UserPlus}
          iconColor="text-red-600"
          progressColor="stroke-red-500"
        />
      </div>

      {/* Main Content Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Revenue Chart */}
        <div className="bg-white p-6 rounded-xl shadow-lg">
          <h2 className="text-lg font-semibold text-gray-900 mb-6">Chiffre d'affaires</h2>
          <RevenueChart interventions={filteredInterventions} />
        </div>

        {/* Map */}
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900">Carte des interventions du jour</h2>
          </div>
          <div className="h-[400px]">
            <InterventionsMap
              interventions={todayInterventions}
              showRoute={userProfile?.role === 'technician'}
            />
          </div>
        </div>
      </div>

      {/* Modals */}
      <OverdueInterventionsModal
        isOpen={showOverdueModal}
        onClose={() => setShowOverdueModal(false)}
        interventions={overdueInterventions}
        onSelectIntervention={(intervention) => {
          setSelectedIntervention(intervention);
          setShowOverdueModal(false);
        }}
      />

      {selectedIntervention && (
        <InterventionDetailsModal
          intervention={selectedIntervention}
          isOpen={!!selectedIntervention}
          onClose={() => setSelectedIntervention(null)}
        />
      )}
    </div>
  );
}
import React from 'react';
import { LucideIcon } from 'lucide-react';

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  icon?: LucideIcon;
  actions?: React.ReactNode;
  gradient?: 'blue' | 'green' | 'purple' | 'orange';
}

export function PageHeader({ 
  title, 
  subtitle, 
  icon: Icon, 
  actions,
  gradient = 'blue'
}: PageHeaderProps) {
  const gradientClasses = {
    blue: 'from-blue-600/5 via-blue-500/5 to-indigo-600/5 [&_svg]:text-blue-600',
    green: 'from-emerald-600/5 via-emerald-500/5 to-teal-600/5 [&_svg]:text-emerald-600',
    purple: 'from-purple-600/5 via-purple-500/5 to-indigo-600/5 [&_svg]:text-purple-600',
    orange: 'from-orange-600/5 via-orange-500/5 to-amber-600/5 [&_svg]:text-orange-600'
  };

  return (
    <div className="mb-6">
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4">
        <div className="flex items-center gap-4">
          {Icon && (
            <div className={`w-12 h-12 rounded-xl bg-gradient-to-br ${gradientClasses[gradient]} flex items-center justify-center shadow-sm`}>
              <Icon className="w-6 h-6" />
            </div>
          )}
          <div>
            <h1 className="text-2xl lg:text-3xl font-bold text-gray-900">{title}</h1>
            {subtitle && (
              <p className="text-gray-500 mt-1">{subtitle}</p>
            )}
          </div>
        </div>
        {actions && (
          <div className="flex items-center gap-3">
            {actions}
          </div>
        )}
      </div>
    </div>
  );
}
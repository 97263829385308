import React, { createContext, useContext, useState, useEffect } from 'react';
import { getClients, createClient, updateClient, deleteClient } from '../lib/firebase/clients';

interface Client {
  id: string;
  name: string;
  type: 'company' | 'individual';
  address: string;
  phone: string;
  email: string;
  contactName?: string;
  notes?: string;
  status: 'active' | 'inactive';
  userId?: string;
}

interface ClientsContextType {
  clients: Client[];
  loading: boolean;
  error: string | null;
  createNewClient: (client: Omit<Client, 'id'>, userData?: { email: string; password: string }) => Promise<string>;
  updateClientData: (id: string, data: Partial<Client>) => Promise<void>;
  removeClient: (id: string) => Promise<void>;
  refreshClients: () => Promise<void>;
}

const ClientsContext = createContext<ClientsContextType | null>(null);

export function ClientsProvider({ children }: { children: React.ReactNode }) {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [refreshKey, setRefreshKey] = useState(0); // Ajout d'une clé de rafraîchissement

  const fetchClients = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getClients();
      setClients(data);
    } catch (err) {
      setError('Erreur lors du chargement des clients');
      console.error('Error fetching clients:', err);
    } finally {
      setLoading(false);
    }
  };

  // Charger les clients au montage et quand refreshKey change
  useEffect(() => {
    fetchClients();
  }, [refreshKey]);

  // Rafraîchissement automatique toutes les 30 secondes
  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshKey(prev => prev + 1);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const createNewClient = async (clientData: Omit<Client, 'id'>, userData?: { email: string; password: string }) => {
    const id = await createClient(clientData, userData);
    setRefreshKey(prev => prev + 1);
    return id;
  };

  const updateClientData = async (id: string, data: Partial<Client>) => {
    await updateClient(id, data);
    setRefreshKey(prev => prev + 1);
  };

  const removeClient = async (id: string) => {
    await deleteClient(id);
    setRefreshKey(prev => prev + 1);
  };

  const value = {
    clients,
    loading,
    error,
    createNewClient,
    updateClientData,
    removeClient,
    refreshClients: () => {
      setRefreshKey(prev => prev + 1);
      return Promise.resolve();
    }
  };

  return (
    <ClientsContext.Provider value={value}>
      {children}
    </ClientsContext.Provider>
  );
}

export function useClients() {
  const context = useContext(ClientsContext);
  if (!context) {
    throw new Error('useClients must be used within a ClientsProvider');
  }
  return context;
}
import React, { useState, useEffect } from 'react';
import { Building2, MapPin, Phone, Mail, Globe, Save, Loader2, AlertCircle } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getCompany, updateCompanyProfile, createCompany, uploadCompanyLogo } from '../../lib/firebase/company';

interface CompanyProfileData {
  name: string;
  address: string;
  phone: string;
  email: string;
  website: string;
  description: string;
  logo?: string;
  siren: string;
}

export function CompanyProfile() {
  const { userProfile, refreshUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);

  const [formData, setFormData] = useState<CompanyProfileData>({
    name: '',
    address: '',
    phone: '',
    email: '',
    website: '',
    description: '',
    logo: undefined,
    siren: ''
  });

  useEffect(() => {
    const loadCompanyData = async () => {
      if (!userProfile?.id) return;

      try {
        // If user has a company, load its data
        if (userProfile.company) {
          const companyData = await getCompany(userProfile.company);
          if (companyData) {
            setFormData({
              name: companyData.name || '',
              address: companyData.address || '',
              phone: companyData.phone || '',
              email: companyData.email || '',
              website: companyData.website || '',
              description: companyData.description || '',
              logo: companyData.logo,
              siren: companyData.siren || ''
            });
            if (companyData.logo) {
              setLogoPreview(companyData.logo);
            }
          }
        }
      } catch (err) {
        console.error('Error loading company:', err);
        setError('Erreur lors du chargement des informations de l\'entreprise');
      }
    };

    loadCompanyData();
  }, [userProfile]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userProfile?.id) {
      setError('ID utilisateur non trouvé');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      let logoUrl = formData.logo;
      if (logoFile) {
        logoUrl = await uploadCompanyLogo(userProfile.company || userProfile.id, logoFile);
      }

      const updateData = {
        ...formData,
        logo: logoUrl
      };

      if (userProfile.company) {
        // Update existing company
        await updateCompanyProfile(userProfile.company, updateData);
      } else {
        // Create new company and link it to user
        const companyId = await createCompany(userProfile.id, updateData);
        await refreshUser(); // Refresh user data to get the new company ID
      }

      setSuccess('Informations mises à jour avec succès');
      setLogoFile(null);
    } catch (err) {
      console.error('Error updating company profile:', err);
      setError('Erreur lors de la mise à jour des informations');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="bg-white p-6 rounded-xl shadow-sm">
      <h2 className="text-lg font-semibold text-gray-900 mb-6">Informations de l'entreprise</h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        {error && (
          <div className="p-3 bg-red-50 border border-red-200 rounded-lg flex items-center gap-2 text-red-600">
            <AlertCircle className="w-5 h-5 flex-shrink-0" />
            <p className="text-sm">{error}</p>
          </div>
        )}

        {success && (
          <div className="p-3 bg-green-50 border border-green-200 rounded-lg text-green-600 text-sm">
            {success}
          </div>
        )}

        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
            Nom de l'entreprise
          </label>
          <div className="relative">
            <Building2 className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
            <input
              type="text"
              id="name"
              name="name"
              required
              value={formData.name}
              onChange={handleChange}
              className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>

        <div>
          <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
            Adresse
          </label>
          <div className="relative">
            <MapPin className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
            <input
              type="text"
              id="address"
              name="address"
              required
              value={formData.address}
              onChange={handleChange}
              className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Adresse complète"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
              Téléphone
            </label>
            <div className="relative">
              <Phone className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
              <input
                type="tel"
                id="phone"
                name="phone"
                required
                value={formData.phone}
                onChange={handleChange}
                className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <div className="relative">
              <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
              <input
                type="email"
                id="email"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
                className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        <div>
          <label htmlFor="website" className="block text-sm font-medium text-gray-700 mb-1">
            Site web
          </label>
          <div className="relative">
            <Globe className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
            <input
              type="url"
              id="website"
              name="website"
              value={formData.website}
              onChange={handleChange}
              className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="https://www.example.com"
            />
          </div>
        </div>

        <div>
          <label htmlFor="siren" className="block text-sm font-medium text-gray-700 mb-1">
            Numéro SIREN
          </label>
          <input
            type="text"
            id="siren"
            name="siren"
            required
            pattern="[0-9]{9}"
            value={formData.siren}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="123456789"
          />
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            rows={4}
            value={formData.description}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
          >
            {loading ? (
              <>
                <Loader2 className="w-5 h-5 animate-spin" />
                Mise à jour...
              </>
            ) : (
              <>
                <Save className="w-5 h-5" />
                Enregistrer
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
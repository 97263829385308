import React from 'react';
import { HelpCircle, Book, MessageCircle, Phone, Mail, ExternalLink } from 'lucide-react';

export function Help() {
  const faqs = [
    {
      question: "Comment créer une nouvelle intervention ?",
      answer: "Pour créer une nouvelle intervention, cliquez sur le bouton '+ Nouvelle intervention' dans la page Interventions. Remplissez ensuite le formulaire avec les détails nécessaires."
    },
    {
      question: "Comment assigner un technicien ?",
      answer: "Dans les détails d'une intervention, utilisez le menu déroulant 'Technicien assigné' pour sélectionner un technicien disponible."
    },
    {
      question: "Comment suivre un colis ?",
      answer: "Dans les détails d'une intervention, cliquez sur le bouton 'Suivre un colis' et entrez le numéro de suivi fourni par le transporteur."
    }
  ];

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900">Centre d'aide</h1>

      {/* Quick Help Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
            <Book className="w-6 h-6 text-blue-600" />
          </div>
          <h3 className="font-medium text-gray-900 mb-2">Documentation</h3>
          <p className="text-gray-600 mb-4">Consultez notre documentation détaillée pour tout savoir sur l'utilisation de DeltAPP.</p>
          <a href="#" className="text-blue-600 hover:text-blue-800 flex items-center gap-2">
            Voir la documentation
            <ExternalLink className="w-4 h-4" />
          </a>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
            <MessageCircle className="w-6 h-6 text-blue-600" />
          </div>
          <h3 className="font-medium text-gray-900 mb-2">Support Chat</h3>
          <p className="text-gray-600 mb-4">Besoin d'aide ? Notre équipe de support est disponible en chat du lundi au vendredi.</p>
          <button className="text-blue-600 hover:text-blue-800 flex items-center gap-2">
            Démarrer un chat
            <ExternalLink className="w-4 h-4" />
          </button>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
            <Phone className="w-6 h-6 text-blue-600" />
          </div>
          <h3 className="font-medium text-gray-900 mb-2">Contact</h3>
          <p className="text-gray-600 mb-4">Pour toute question urgente, contactez-nous directement.</p>
          <div className="space-y-2">
            <p className="flex items-center gap-2 text-gray-600">
              <Phone className="w-4 h-4" />
              0800 800 800
            </p>
            <p className="flex items-center gap-2 text-gray-600">
              <Mail className="w-4 h-4" />
              support@deltapp.fr
            </p>
          </div>
        </div>
      </div>

      {/* FAQs Section */}
      <div className="bg-white p-6 rounded-xl shadow-sm">
        <h2 className="text-xl font-semibold text-gray-900 mb-6">Questions fréquentes</h2>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div key={index} className="border-b border-gray-200 last:border-0 pb-6 last:pb-0">
              <h3 className="font-medium text-gray-900 flex items-center gap-2 mb-2">
                <HelpCircle className="w-5 h-5 text-blue-600" />
                {faq.question}
              </h3>
              <p className="text-gray-600 ml-7">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Video Tutorials */}
      <div className="bg-white p-6 rounded-xl shadow-sm">
        <h2 className="text-xl font-semibold text-gray-900 mb-6">Tutoriels vidéo</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[1, 2, 3].map((_, index) => (
            <div key={index} className="aspect-video bg-gray-100 rounded-lg flex items-center justify-center">
              <p className="text-gray-500">Tutoriel {index + 1}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
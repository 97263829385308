import React, { useEffect, useRef, useState } from 'react';
import { MapPin, Loader2 } from 'lucide-react';
import { Loader } from '@googlemaps/js-api-loader';
import type { ValidatedAddress } from '../../types';

interface AddressInputProps {
  value: string;
  onChange: (address: string, validated?: ValidatedAddress) => void;
  required?: boolean;
  error?: string;
  placeholder?: string;
}

export function AddressInput({ 
  value, 
  onChange, 
  required = false, 
  error,
  placeholder = "Saisissez une adresse..."
}: AddressInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyCwuBL4RWpZuGQ2twJuavcx51S7Q7Xk7ow',
      version: 'weekly',
      libraries: ['places']
    });

    const initAutocomplete = async () => {
      try {
        setLoading(true);
        await loader.load();
        
        if (!inputRef.current) return;

        // Créer l'autocomplete
        autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current, {
          componentRestrictions: { country: 'fr' },
          fields: ['formatted_address', 'geometry'],
          types: ['address']
        });

        // Écouter les sélections
        autocompleteRef.current.addListener('place_changed', () => {
          const place = autocompleteRef.current?.getPlace();
          
          if (place?.formatted_address && place.geometry?.location) {
            const validatedAddress: ValidatedAddress = {
              formattedAddress: place.formatted_address,
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            };
            onChange(place.formatted_address, validatedAddress);
          }
        });

        setInitialized(true);
      } catch (err) {
        console.error('Error initializing Google Places:', err);
      } finally {
        setLoading(false);
      }
    };

    if (!initialized) {
      initAutocomplete();
    }
  }, [onChange, initialized]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="relative">
      <div className="relative">
        <MapPin className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
        <input
          ref={inputRef}
          type="text"
          value={value}
          onChange={handleChange}
          required={required}
          disabled={loading}
          className={`pl-10 w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 ${
            error ? 'border-red-300' : 'border-gray-300'
          } ${loading ? 'bg-gray-50' : ''}`}
          placeholder={loading ? 'Chargement...' : placeholder}
        />
        {loading && (
          <div className="absolute right-3 top-1/2 -translate-y-1/2">
            <Loader2 className="w-5 h-5 text-gray-400 animate-spin" />
          </div>
        )}
      </div>

      {error && (
        <p className="mt-1 text-sm text-red-600">
          {error}
        </p>
      )}
    </div>
  );
}
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getMonthlyInvoices, createMonthlyInvoice, updateMonthlyInvoice } from '../lib/firebase/accounting';
import type { MonthlyInvoice } from '../types';

interface AccountingContextType {
  invoices: MonthlyInvoice[];
  loading: boolean;
  error: string | null;
  selectedMonth: number;
  selectedYear: number;
  setSelectedMonth: (month: number) => void;
  setSelectedYear: (year: number) => void;
  createInvoice: (invoice: Omit<MonthlyInvoice, 'id' | 'createdAt' | 'updatedAt'>) => Promise<string>;
  updateInvoice: (id: string, data: Partial<MonthlyInvoice>) => Promise<void>;
  refreshInvoices: () => Promise<void>;
}

const AccountingContext = createContext<AccountingContextType | null>(null);

export function AccountingProvider({ children }: { children: React.ReactNode }) {
  const [invoices, setInvoices] = useState<MonthlyInvoice[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedMonth, setSelectedMonth] = useState(() => new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(() => new Date().getFullYear());

  const fetchInvoices = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getMonthlyInvoices(selectedMonth, selectedYear);
      setInvoices(data);
    } catch (err) {
      console.error('Error fetching invoices:', err);
      setError('Erreur lors du chargement des factures');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, [selectedMonth, selectedYear]);

  const createInvoice = async (invoice: Omit<MonthlyInvoice, 'id' | 'createdAt' | 'updatedAt'>) => {
    const id = await createMonthlyInvoice(invoice);
    await fetchInvoices();
    return id;
  };

  const updateInvoice = async (id: string, data: Partial<MonthlyInvoice>) => {
    await updateMonthlyInvoice(id, data);
    await fetchInvoices();
  };

  const value = {
    invoices,
    loading,
    error,
    selectedMonth,
    selectedYear,
    setSelectedMonth,
    setSelectedYear,
    createInvoice,
    updateInvoice,
    refreshInvoices: fetchInvoices
  };

  return (
    <AccountingContext.Provider value={value}>
      {children}
    </AccountingContext.Provider>
  );
}

export function useAccounting() {
  const context = useContext(AccountingContext);
  if (!context) {
    throw new Error('useAccounting must be used within an AccountingProvider');
  }
  return context;
}
import { EmailTemplate } from './templates';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';

interface SendEmailParams {
  to: string;
  template: EmailTemplate;
}

export async function sendEmail({ to, template }: SendEmailParams): Promise<void> {
  try {
    const sendEmailFunction = httpsCallable(functions, 'sendEmail');
    await sendEmailFunction({
      to,
      subject: template.subject,
      html: template.body
    });
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
}
import React, { useState } from 'react';
import { Bell, Mail, AlertCircle, Clock, Calendar } from 'lucide-react';

interface NotificationSetting {
  id: string;
  title: string;
  description: string;
  icon: React.ElementType;
  enabled: boolean;
}

export function NotificationPreferences() {
  const [settings, setSettings] = useState<NotificationSetting[]>([
    {
      id: 'new_intervention',
      title: 'Nouvelle intervention',
      description: 'Recevoir une notification lors de la création d\'une intervention',
      icon: Bell,
      enabled: true
    },
    {
      id: 'intervention_update',
      title: 'Mise à jour d\'intervention',
      description: 'Recevoir une notification lors de la modification d\'une intervention',
      icon: Mail,
      enabled: true
    },
    {
      id: 'intervention_reminder',
      title: 'Rappel d\'intervention',
      description: 'Recevoir un rappel la veille d\'une intervention',
      icon: Clock,
      enabled: true
    },
    {
      id: 'daily_summary',
      title: 'Résumé quotidien',
      description: 'Recevoir un résumé quotidien des interventions',
      icon: Calendar,
      enabled: false
    }
  ]);

  const toggleSetting = (id: string) => {
    setSettings(prev => 
      prev.map(setting => 
        setting.id === id 
          ? { ...setting, enabled: !setting.enabled }
          : setting
      )
    );
  };

  return (
    <div className="bg-white p-6 rounded-xl shadow-sm">
      <div className="flex items-center gap-2 mb-6">
        <Bell className="w-5 h-5 text-blue-600" />
        <h2 className="text-lg font-semibold text-gray-900">Notifications</h2>
      </div>

      <div className="space-y-4">
        {settings.map(setting => (
          <div key={setting.id} className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="w-8 h-8 bg-gray-100 rounded-lg flex items-center justify-center">
                <setting.icon className="w-4 h-4 text-gray-600" />
              </div>
              <div>
                <p className="font-medium text-gray-900">{setting.title}</p>
                <p className="text-sm text-gray-500">{setting.description}</p>
              </div>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={setting.enabled}
                onChange={() => toggleSetting(setting.id)}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}